import { colors } from "src/config/colors";

export const components = {
  MuiRadio: {
    root: {
      color: colors.opacityWhite,
      padding: 5,
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: {
        margin: "0 4px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontSize: "16px",
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        borderBottom: `2px #FFFFFF solid`,
        "&.Mui-selected": {
          borderBottom: `2px ${colors.yellow} solid`,
          paddingTop: 0,
        },
      },
      label: {
        fontSize: 8,
        lineHeight: "18px",
        fontWeight: 400,
        color: colors.blue,
        "&.Mui-selected": {
          fontSize: 8,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      iconOpen: {
        right: 0,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        ":hover .discountText": {
          backgroundColor: colors.yellow,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltipPlacementBottom: {
        marginTop: "6px !important",
        textAlign: "center",
        fontSize: "12px",
        color: colors.yellow,
        width: "fit-content"
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      label: {
        fontSize: 12,
        lineHeight: "12px",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: 12,
        lineHeight: "12px",
      },
    },
  },
};

import React from "react";
import Container from "src/components/common/Layout/Container";
import { Typography } from "@mui/material";
import { colors } from "src/config/colors";

const Disclaimer: React.FC = () => (
  <Container paddingBottom={4} paddingTop={4}>
    <Typography
      sx={{
        fontSize: { xs: "14px", sm: "16px" },
        lineHeight: { xs: "19px", sm: "22px" },
        color: colors.black2,
      }}
      component="p"
    >
      Вся представленная на сайте информация носит информационный характер и ни
      при каких условиях не является публичной офертой, определяемой положениями
      Статьи 437(2) Гражданского кодекса РФ.
      <br /> Возрастная категория 18+.
    </Typography>
  </Container>
);

export default Disclaimer;

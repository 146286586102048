import * as React from "react";

function SvgNewOrdersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={25}
      fill="#909090"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M21.821 7.048l-8.758-4.416a1.266 1.266 0 00-1.128 0L3.18 7.049c-.42.21-.679.63-.679 1.096v8.712c0 .465.26.885.679 1.095l8.758 4.416a1.26 1.26 0 001.128 0l8.755-4.415c.42-.211.68-.63.68-1.096V8.144a1.215 1.215 0 00-.679-1.096zm-9.354-3.257l7.921 3.995-7.888 3.978-7.892-3.98 7.86-3.993zm-8.653 5.05l8.029 4.05v7.97l-8.03-4.05v-7.97zm9.342 12.05v-8l8.03-4.049v7.971l-8.03 4.078z" />
    </svg>
  );
}

export default SvgNewOrdersIcon;

import React from "react";
import { colors } from "src/config/colors";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import SubmitButton from "src/components/common/Buttons/SubmitButton";
import { toastAlert } from "src/components/common/SnackbarAlert/toastAlert";
import FieldMask from "src/components/common/FormComponents/FieldMask";
import Field from "src/components/common/FormComponents/Field";
import { FeedbackAlert } from "src/components/common/SnackbarAlert/AlertMsgContent/FeedbackAlert";
import { useSelector } from "react-redux";
import { userInfoSelector } from "src/redux/slice/authSlice";
import { sendFeedback } from "src/models/feedBack/sendFeedBack";

export type ModalFeedbackInputs = {
  name: string;
  phone: string;
  msg: string;
  rating: number;
};

interface FeedbackFormProps {
  closeModal: () => void;
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number | null>>;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  closeModal,
  rating,
  setRating,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ModalFeedbackInputs>({
    mode: "onSubmit",
    defaultValues: { rating },
  });

  const userInfo = useSelector(userInfoSelector);

  const onSubmit: SubmitHandler<ModalFeedbackInputs> = (data) => {
    sendFeedback(data)
      .then(() => {
        closeModal();
        toastAlert(FeedbackAlert, "success", {
          autoClose: 6000,
        });
      })
      .catch((errors) => console.log(errors))
      .finally(() => setRating(null));
  };

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      component="form"
      display="flex"
      alignItems="center"
      flexDirection="column"
      height="500px"
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Typography
          component="h4"
          textAlign="center"
          fontSize={{ xs: "15px", sm: "16px" }}
          color={colors.darkBlue}
        >
          {rating === 1 || rating === 2 ? (
            <>
              <b>Вы чем то расстроены?</b> <br /> Пожалуйста, поделитесь этим с
              нами
            </>
          ) : rating === 3 || rating === 4 ? (
            <>
              <b>Спасибо!</b> <br /> Что можно улучшить?
            </>
          ) : (
            <>
              <b>Спасибо!</b> <br /> Может что-то можно улучшить?
            </>
          )}
        </Typography>
        <Field
          sx={{ backgroundColor: colors.white, borderRadius: "8px" }}
          register={register("msg")}
          rows={3}
          placeholder="Ваше сообщение"
          multiline
          inputProps={{ maxLength: 600 }}
        />
      </Box>
      <Box
        display="flex"
        width="100%"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        mb={{ xs: "13px", sm: "20px" }}
        mt={{ xs: "13px", sm: "20px" }}
      >
        <Box mr={{ xs: "0px", sm: "15px" }} mb={{ xs: "8px", sm: "0px" }}>
          <Typography
            component="h4"
            fontSize={{ xs: "11px", sm: "13px" }}
            lineHeight="13px"
            color={colors.darkBlue}
          >
            Ваше имя (не обязательно)
          </Typography>
          <Field
            sx={{
              backgroundColor: colors.white,
              borderRadius: "8px",
              width: "100%",
            }}
            type="name"
            error={errors.name?.message}
            register={register("name")}
            fullWidth
            placeholder="Ваше имя"
            defaultValue={userInfo?.name}
          />
        </Box>
        <Box>
          <Typography
            component="h4"
            fontSize={{ xs: "11px", sm: "13px" }}
            lineHeight="13px"
            color={colors.darkBlue}
          >
            Номер телефона (не обязательно)
          </Typography>
          <FieldMask
            sx={{
              backgroundColor: colors.white,
              borderRadius: "8px",
              width: "100%",
            }}
            notRequired
            control={control}
            placeholder="+7 (999) 999-99-99"
            defaultValue={userInfo?.phone}
          />
        </Box>
      </Box>
      <SubmitButton
        type="submit"
        title="Отправить"
        sx={{
          backgroundColor: colors.darkRed,
          color: colors.white,
          ":hover": {
            backgroundColor: colors.darkRed,
            opacity: "50%",
          },
        }}
        minHeight={30}
        height={45}
        width={170}
      />
      <Box>
        <Typography
          marginY={{ xs: "20px", sm: "30px" }}
          component="h4"
          textAlign="center"
          fontSize={{ xs: "15px", sm: "16px" }}
          color={colors.darkBlue}
        >
          Ваш комментарий анонимный. Если вы хотите получить ответ, напишите на
          info@cenyvaptekah.ru, либо оставьте свои данные и мы свяжемся с вами!
        </Typography>
      </Box>
    </Box>
  );
};

export default FeedbackForm;

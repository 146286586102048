import React, { useMemo } from "react";
import styled from "src/components/common/BottomNavigation/styled";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  NoSsr,
} from "@mui/material";
import { CartIcon } from "src/assets/icons";
import IconButton from "@mui/material/IconButton";
import { createUrl } from "src/utils/helpers/url/createUrl";
import paths from "src/router/paths";
import { useRouter } from "next/router";
import Badge from "@mui/material/Badge";
import { colors } from "src/config/colors";
import { calcProductInBasket } from "src/utils/helpers/calculate/calcProductInBasket";
import { shallowEqual, useSelector } from "react-redux";
import { selectCount } from "src/redux/slice/basketSlice";
import DrawerMenu from "src/components/common/DrawerMenu/DrawerMenu";
import { selectCity } from "src/redux/slice/citySlice";
import { useModalContext } from "src/components/common/Layout/context/ModalContext";
import FeedbackIcon from "src/assets/icons/FeedbackIcon";
import SearchIcon2 from "src/assets/icons/SearchIcon2";
import { ModalType } from "src/utils/hooks/state/useModal";
import {
  EnumMetrikActions,
  sendCustomYandexMetrik,
} from "src/config/analytics/sendCustomYandexMetrik";
import InventoryIcon from "@mui/icons-material/Inventory";

const BottomMobileNavigation: React.FC = () => {
  const router = useRouter();
  const basketObject = useSelector(selectCount);
  const countProduct = useMemo(
    () => calcProductInBasket(basketObject),
    [basketObject]
  );
  const cityInfo = useSelector(selectCity, shallowEqual);
  const { openModal } = useModalContext();
  /*const { authStage } = useSelector(authData, shallowEqual);
  const isAuth = authStage === AuthStage.auth;

  const authDone = () => {
    sendCustomYandexMetrik(EnumMetrikActions.clickOnOrdersBtnInFooterMenu);
    return router.push("/profile/orders");
  };

  const authNotCompleted = () => {
    openModal(ModalType.AUTHORIZATION);
    sendCustomYandexMetrik(EnumMetrikActions.clickOnAuthBtnInFooterMenu);
  };*/

  return (
    <>
      <BottomNavigation showLabels sx={styled.bottomNav}>
        <BottomNavigationAction
          component="div"
          value="search"
          sx={styled.bottomNavButton}
          label="Поиск"
          icon={<SearchIcon2 />}
          onClick={() => {
            sendCustomYandexMetrik(
              EnumMetrikActions.clickOnSearchBtnInFooterMenu
            );
            openModal(ModalType.SEARCH);
          }}
        />
        <BottomNavigationAction
          component="div"
          value="basket"
          sx={styled.bottomNavButton}
          label="Корзина"
          icon={
            <NoSsr>
              <Box suppressHydrationWarning>
                <IconButton sx={{ padding: "0px" }} aria-label="menu">
                  <Badge
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: colors.red,
                        color: colors.white,
                        padding: "0px",
                      },
                    }}
                    badgeContent={countProduct}
                    max={99}
                  >
                    <CartIcon />
                  </Badge>
                </IconButton>
              </Box>
            </NoSsr>
          }
          onClick={() => {
            sendCustomYandexMetrik(
              EnumMetrikActions.clickOnCartBtnInFooterMenu
            );
            return router.push(createUrl([paths.BASKET]));
          }}
        />
        <BottomNavigationAction
          component="div"
          value="feedback"
          sx={styled.bottomNavButton}
          label="Отзыв"
          icon={<FeedbackIcon />}
          onClick={() => {
            sendCustomYandexMetrik(
              EnumMetrikActions.clickOnReviewBtnInFooterMenu
            );
            openModal(ModalType.FEEDBACK);
          }}
        />
        {/*<BottomNavigationAction
          component="div"
          value="profile"
          sx={styled.bottomNavButton}
          label={isAuth ? "Заказы" : "Войти"}
          icon={isAuth ? <OrdersIcon /> : <ProfileIcon />}
          onClick={() => {
            isAuth ? authDone() : authNotCompleted();
          }}
        />*/}
        <BottomNavigationAction
          component="div"
          value="profile"
          sx={styled.bottomNavButton}
          label="Заказы"
          icon={<InventoryIcon />}
          onClick={() => openModal(ModalType.CHECK_ORDER_STATUS)}
        />
        <BottomNavigationAction
          component="div"
          value="menu"
          sx={styled.bottomNavButton}
          label="Меню"
          icon={
            <DrawerMenu
              sx={{ padding: "0px" }}
              cityName={cityInfo?.name}
              citySlug={cityInfo?.slug}
              openModal={openModal}
            />
          }
        />
      </BottomNavigation>
    </>
  );
};

export default BottomMobileNavigation;

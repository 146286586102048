import React from "react";
import { Box } from "@mui/system";
import Image from "next/image";
import { Typography } from "@mui/material";
import { colors } from "src/config/colors";
import feedBack_icon from "src/assets/feedBack_icon.png";

interface FeedbackButtonProps {
  onClick?: () => void;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: { xs: "none", lg: "flex" },
        width: "50px",
        height: "170px",
        backgroundColor: colors.yellow,
        borderRadius: "0px 8px 8px 0px",
        zIndex: 3000,
        position: "fixed",
        right: 0,
        top: "60%",
        boxShadow: "1.4px 0px 7px rgba(0, 0, 0, 0.25)",
        writingMode: "vertical-rl",
        transform: "rotate(180deg)",
        padding: "10px",
        justifyContent: "space-evenly",
        cursor: "pointer",
        ":hover": {
          opacity: "50%",
        },
      }}
    >
      <Box
        sx={{
          transform: "rotate(180deg)",
        }}
      >
        <Image
          alt="feedback_icon"
          width="27px"
          height="27px"
          src={feedBack_icon.src}
        />
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          lineHeight: "14px",
          color: "#20201C",
        }}
      >
        Оставить <br /> отзыв
      </Typography>
    </Box>
  );
};

export default FeedbackButton;

import React, { useContext } from "react";
import useModal from "src/utils/hooks/state/useModal";

const ModalContext = React.createContext<any>({});

const ModalProvider: React.FC = ({ children }) => {
  const { modal: modalType, openModal, closeModal } = useModal();

  return (
    <ModalContext.Provider value={{ modalType, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

const useModalContext = () => useContext(ModalContext);

export { useModalContext, ModalProvider };

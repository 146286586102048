import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AppState } from "src/redux/store";
import { setBasketToStorage } from "src/utils/helpers/redux/setBasketToStorage";
import { UpdateOrderItems } from "src/models/orders/postUpdateOrder";

export interface ProductCart {
  counter: number;
  price: number;
  name: string;
  slug: string;
  mnn: string;
  address: string;
  imgSource: string;
  pharmacyName: string;
  pharmacyPhone: string;
  pharmacySchedule: string;
  minPriceForBuy: number;
  cartInfo: string[];
  productId: number;
  fullName: string;
  bookingInfo: string;
  brandName: string;
  quantity: number;
  actual_price?: number;
  actual_qty?: number;
}

export interface PharmacyProduct {
  [pharmacyId: number]: ProductCart;
}

export interface BasketState {
  [childProductId: number]: PharmacyProduct;
}

export interface ProductIdentificator {
  pharmacyId: string;
  childProductId: string;
}

export interface PharmacyIdentificator {
  pharmacyId: string;
}

export interface ValidateBasket {
  pharmacyId: number;
  products: UpdateOrderItems[];
}

const initialState = () => {
  if (typeof window !== "undefined") {
    const basketStorage = localStorage.getItem("basket");

    if (basketStorage) {
      const basketStorageData = JSON.parse(basketStorage);
      for (let key in basketStorageData) {
        for (let prod in basketStorageData[key]) {
          if (
            !basketStorageData[key][prod]?.fullName ||
            !basketStorageData[key][prod]?.productId
          ) {
            localStorage.removeItem("basket");
            return {};
          }
        }
      }
      return basketStorageData as BasketState;
    }
  }
  return {};
};

export const basketSlice = createSlice({
  name: "basket",
  initialState: initialState(),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addProduct: (state, action: PayloadAction<BasketState>) => {
      const actionKey = Number(Object.keys(action.payload)[0]);

      if (state[actionKey]) {
        const newPharmCart = {
          ...state[actionKey],
          ...action.payload[actionKey],
        };
        state[actionKey] = newPharmCart;
        setBasketToStorage(state);
        return state;
      }
      setBasketToStorage({ ...state, ...action.payload });

      return { ...state, ...action.payload };
    },
    removeProduct: (state, action: PayloadAction<ProductIdentificator>) => {
      const { pharmacyId, childProductId } = action.payload;
      delete state[pharmacyId][childProductId];
      if (Object.keys(state[pharmacyId]).length <= 0) {
        delete state[pharmacyId];
      }
      setBasketToStorage(state);
    },
    incrementCount: (state, action: PayloadAction<ProductIdentificator>) => {
      const { pharmacyId, childProductId } = action.payload;
      const counter = state[pharmacyId][childProductId].counter;
      state[pharmacyId][childProductId].counter = counter + 1;
      setBasketToStorage(state);

      return state;
    },
    decrementCount: (state, action: PayloadAction<ProductIdentificator>) => {
      const { pharmacyId, childProductId } = action.payload;
      const counter = state[pharmacyId][childProductId].counter;
      state[pharmacyId][childProductId].counter = counter - 1;
      setBasketToStorage(state);

      return state;
    },
    clearPharmacy: (state, action: PayloadAction<PharmacyIdentificator>) => {
      const { pharmacyId } = action.payload;

      delete state[pharmacyId];
      setBasketToStorage(state);
    },
    clearBasket: () => {
      localStorage.removeItem("basket");
      return {};
    },
    validateBasket: (state, action: PayloadAction<ValidateBasket>) => {
      const { pharmacyId, products } = action.payload;

      products.forEach((value) => {
        state[pharmacyId][value.product_id].actual_price = value.actual_price;
        state[pharmacyId][value.product_id].quantity = value.actual_qty;
      });
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //     builder
  //         .addCase(incrementAsync.pending, (state) => {
  //             state.status = 'loading'
  //         })
  //         .addCase(incrementAsync.fulfilled, (state, action) => {
  //             state.status = 'idle'
  //             state.value += action.payload
  //         })
  // },
});

export const basketSliceActions = basketSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: AppState) => state.basket;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

// export const empty = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const products = selectCount(getState())
//     if (!products.length) {
//         dispatch(incrementByAmount(amount))
//     }
// }

export default basketSlice.reducer;

const paths = {
  BLOG: "blog",
  ABOUT: "about",
  ARTICLE: "article",
  CATALOG: "catalog",
  ANALOGI: "analogi",
  CATEGORY: "category",
  PHARMACIES24: "kruglosutochnye-apteki",
  PRODUCT: "product",
  INFO: "info",
  ARTICLES: "articles",
  APTEKI: "apteki",
  SPECIFICATION: "specification",
  MEDICINES: "medicines",
  BRANDS: "brands",
  ANALOGS: "analogs",
  INSTRUCTION: "instruction",
  ORDERING: "ordering",
  FILTER_PRODUCT: "brand-any/form-any/dos-any/pack-any",
  FILTER_PRODUCT_WITHOUT_PACK: "brand-any/form-any/dos-any",
  PHARMACY_COMPANIES: "pharmacy-companies",
  ORDER_STATUS: "order-status",
  SYMPTOMS: "symptoms",
  SYMPTOMS_WITH_BRANDS: "symptoms-with-brands",
  COOKIE: "cookie",
  DISCLAIMER: "disclaimer",
  FAQ: "faq",
  FEEDBACK: "feedback",
  MAP_AGREEMENT: "https://yandex.ru/legal/maps_termsofuse/",
  CONTACTS: "contacts",
  ADD_MY_PHARM: "add-my-pharm",
  POLICY_PD: "policy-pd",
  USER_AGREEMENT: "user-agreement",
  SYMPTOM: "symptom",
  BASKET: "basket",
  CLASSIFICATIONS: "classifications",
  COMPANIES: "companies",
  COOP_WITH_PHARMACIES: "coop-with-pharmacies",
  PROFILE_INFO: "/profile/user",
  PROFILE_ORDERS: "/profile/orders",
};

export default paths;

import Cookies from "js-cookie";
import { ACCESS_TOKEN_KEY } from "src/config";
import { Maybe } from "src/types";

export const getAccessToken = (): Maybe<string> => {
  const accessToken = Cookies.get(ACCESS_TOKEN_KEY);

  if (accessToken) {
    return accessToken;
  }
  return null;
};

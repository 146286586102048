import * as React from "react";

function SvgActiveFilterCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.5 3.32h7.613a2.333 2.333 0 002.274 1.829 2.333 2.333 0 002.274-1.828H15.5a.5.5 0 000-1h-2.839A2.335 2.335 0 0010.387.492c-1.11 0-2.044.785-2.274 1.829H.5a.5.5 0 000 1zm8.559-.498v-.006a1.33 1.33 0 011.328-1.324c.73 0 1.325.593 1.328 1.323v.008a1.33 1.33 0 01-1.328 1.326 1.33 1.33 0 01-1.328-1.325v-.002zM15.5 13.679h-2.839a2.335 2.335 0 00-2.274-1.828c-1.11 0-2.044.784-2.274 1.828H.5a.5.5 0 000 1h7.613a2.332 2.332 0 002.274 1.829 2.333 2.333 0 002.274-1.829H15.5a.5.5 0 100-1zm-5.113 1.829a1.33 1.33 0 01-1.328-1.326v-.007a1.33 1.33 0 011.328-1.324c.73 0 1.325.593 1.328 1.323v.007a1.33 1.33 0 01-1.328 1.327zM15.5 8H7.887a2.332 2.332 0 00-2.274-1.828A2.333 2.333 0 003.339 8H.5a.5.5 0 000 1h2.839a2.335 2.335 0 002.274 1.828c1.11 0 2.044-.784 2.274-1.828H15.5a.5.5 0 000-1zm-8.559.499v.005a1.33 1.33 0 01-1.328 1.324c-.73 0-1.325-.593-1.328-1.323v-.007a1.33 1.33 0 011.328-1.326 1.33 1.33 0 011.328 1.325v.002z"
        fill="#20201C"
      />
    </svg>
  );
}

export default SvgActiveFilterCircle;

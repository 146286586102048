import React from "react";
import { Box, Paper } from "@mui/material";
import {
  AllSystemCSSProperties,
  ResponsiveStyleValue,
  StandardCSSProperties,
  SxProps,
} from "@mui/system";
import { Theme } from "@mui/material/styles";
import { conditionsSx } from "src/utils/helpers/style/conditionsSx";

interface SectionProps {
  isWithPaper?: boolean;
  paddingX?: ResponsiveStyleValue<AllSystemCSSProperties["padding"]>;
  height?: number;
  paddingTop?: ResponsiveStyleValue<AllSystemCSSProperties["padding"]>;
  paddingBottom?: ResponsiveStyleValue<AllSystemCSSProperties["padding"]>;
  marginBottom?: ResponsiveStyleValue<AllSystemCSSProperties["margin"]>;
  bgColor?: string;
  fullHeight?: boolean;
  p?: StandardCSSProperties["padding"];
  sx?: SxProps<Theme>;
  elevation?: number;
  wrapperSx?: SxProps<Theme>;
  classname?: string;
  maxWidth?: "sm" | "md" | "lg";
  disableBorderRadius?: boolean;
  id?: string;
  component?: React.ElementType;
}

const Container: React.FC<SectionProps> = ({
  children,
  isWithPaper,
  paddingX,
  paddingTop,
  paddingBottom,
  height,
  marginBottom,
  bgColor,
  fullHeight,
  p,
  sx,
  elevation = 1,
  wrapperSx,
  classname,
  maxWidth = "1240px",
  disableBorderRadius,
  id,
  component,
}) =>
  isWithPaper ? (
    <Paper
      id={id}
      elevation={elevation}
      square
      sx={{
        height: conditionsSx(fullHeight, "100%"),
        borderRadius: { lg: disableBorderRadius ? "0px" : "5px" },
        ...wrapperSx,
      }}
    >
      <Box
        maxWidth={maxWidth}
        p={p}
        paddingX={paddingX || { xs: 1.5, sm: 2.5, lg: 3.75 }}
        height={height}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        sx={{ height: conditionsSx(fullHeight, "100%"), ...sx }}
        marginBottom={marginBottom}
        marginLeft="auto"
        marginRight="auto"
        position="relative"
        className={classname}
      >
        {children}
      </Box>
    </Paper>
  ) : (
    <Box
      component={component}
      id={id}
      display="flex"
      justifyContent="center"
      sx={wrapperSx}
      bgcolor={bgColor}
    >
      <Box
        maxWidth={maxWidth}
        width="100%"
        paddingX={paddingX || { xs: 1.5, sm: 2.5 }}
        height={height}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        marginBottom={marginBottom}
        position="relative"
        sx={{ height: conditionsSx(fullHeight, "100%"), ...sx }}
      >
        {children}
      </Box>
    </Box>
  );

export default Container;

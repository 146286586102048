import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { createWrapper, HYDRATE, MakeStore } from "next-redux-wrapper";
import basketSlice from "src/redux/slice/basketSlice";
import citySlice from "src/redux/slice/citySlice";
import { authSlice } from "src/redux/slice/authSlice";

const combinedReducers = combineReducers({
  basket: basketSlice,
  city: citySlice,
  authReducer: authSlice.reducer,
});

export type AppState = ReturnType<typeof combinedReducers>;

const rootReducer = (
  state: ReturnType<typeof combinedReducers>,
  action: AnyAction
) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    return nextState;
  }

  return combinedReducers(state, action);
};

export const store = configureStore<AppState>({
  reducer: rootReducer as any,
});

const makeStore: MakeStore<any> = () => store;

export const wrapper = createWrapper(makeStore);

export type AppDispatch = typeof store.dispatch;

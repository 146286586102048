import * as React from "react";

function SvgArrowRightSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22 4.97a.75.75 0 011.06 0l6.5 6.5a.75.75 0 010 1.06l-6.5 6.5a.75.75 0 01-1.06-1.06L14.19 12 8.22 6.03a.75.75 0 010-1.06z"
        fill="#202020"
      />
    </svg>
  );
}

export default SvgArrowRightSearch;

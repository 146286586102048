import { StyledSx } from "src/types/StyledSx";

const SearchProductInputStyled: StyledSx = {
  searchInput: {
    display: "flex",
    alignItems: "center",
    width: { xs: "100%", lg: "497px" },
    minWidth: { xs: "232px" },
    maxWidth: { xs: "100%", lg: "497px" },
    fontSize: 14,
    padding: "16px 12px",
    height: { xs: "40px", lg: "56px" },
    cursor: "pointer",
  },
  input: {
    flex: 1,
    fontSize: { xs: "12px", tb: "14px" },
  },
  iconButton: {
    padding: 1.25,
  },
  searchButton: {
    fontSize: 14,
    fontWeight: 550,
  },
};

export default SearchProductInputStyled;

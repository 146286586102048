import * as React from "react";

function SvgCartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={22}
      fill="#909090"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.996 16.924H11.91c.014.061.023.123.036.18.346 1.281-.645 2.588-1.997 2.605-1.215.018-2.428.009-3.647.005-.417 0-.73-.311-.727-.702.006-.39.315-.693.735-.698.507-.004 1.014 0 1.538 0a2.015 2.015 0 01.017-1.447c-.883-.214-1.555-.689-1.971-1.465-.3-.56-.417-1.162-.269-1.78.077-.349.21-.682.394-.987.184-.289.44-.561.722-.763.298-.207.628-.363.976-.46.336-.097.7-.097 1.049-.137-.447-1.017-.9-2.053-1.357-3.088C6.78 6.754 6.156 5.32 5.526 3.89c-.287-.65-.802-.974-1.523-.983-.417-.004-.834 0-1.255 0-.424-.005-.744-.298-.748-.694-.004-.402.313-.71.748-.705.605.005 1.219-.044 1.805.053 1.058.171 1.847.76 2.276 1.737 1.035 2.338 2.052 4.684 3.083 7.027.287.657.81.986 1.54.99 2.057.005 4.113.005 6.174 0 .717-.004 1.241-.332 1.541-.968.766-1.637 1.523-3.277 2.284-4.913.037-.075.069-.145.095-.224.04-.113-.005-.183-.13-.192-.09-.005-.18-.005-.268-.005H9.298c-.098 0-.197.004-.287-.018a.703.703 0 01-.56-.763c.04-.359.345-.619.744-.619 1.406-.004 2.818 0 4.225 0 2.616 0 5.232 0 7.848.005.193 0 .39.017.573.061.991.25 1.425 1.198.991 2.167-.27.602-.556 1.193-.834 1.79-.51 1.1-1.003 2.21-1.536 3.302-.56 1.15-1.518 1.768-2.822 1.776-3.065.018-6.129.005-9.189.01-.712 0-1.281.473-1.415 1.162a1.374 1.374 0 00.86 1.523c.197.073.42.108.632.108 2.84.009 5.68.004 8.52.004 1 0 1.873.693 2.083 1.637a2.079 2.079 0 01-1.182 2.364c-.802.359-1.582.276-2.263-.271-.685-.554-.932-1.278-.726-2.137.005-.061.018-.12.036-.19zm-4.408.684a.713.713 0 00-.717-.693.712.712 0 00-.712.698c0 .39.332.71.73.701a.709.709 0 00.7-.706zm6.447.71a.712.712 0 00.707-.701.71.71 0 00-.712-.697.713.713 0 00-.717.714.71.71 0 00.722.685zm-13.266 1.4c-.125 0-.25.005-.38 0a.706.706 0 01-.677-.706.702.702 0 01.668-.689c.269-.013.534-.013.801 0a.705.705 0 01.678.707.705.705 0 01-.668.689c-.14.004-.278 0-.422 0z" />
    </svg>
  );
}

export default SvgCartIcon;

import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshToken } from "src/models/auth/refreshToken";
import { getAccessToken } from "src/utils/helpers/cookie/getAuthTokens";
import { isServerRefresh } from "src/utils/helpers/requestApi/isServerRefresh";

const proxyInstance = axios.create({
  baseURL: process.env.BASE_URL + "api",
  withCredentials: true,
});

proxyInstance.interceptors.request.use(
  (config) => {
    const originalRequest = config;

    const isRefreshRequest = originalRequest.url === "users/auth/refresh";
    const accessToken = getAccessToken();

    if (!originalRequest.headers) originalRequest.headers = {};
    if (accessToken && !isRefreshRequest) {
      originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return Promise.resolve(originalRequest);
  },
  (err) => Promise.reject(err)
);

createAuthRefreshInterceptor(proxyInstance, (failedRequest) => {
  return refreshToken(isServerRefresh(failedRequest))
    .then((resp) => {
      const { access_token } = resp.data;
      const bearer = `Bearer ${access_token}`;
      // @ts-ignore
      proxyInstance.defaults.headers.Authorization = bearer;
      failedRequest.response.config.headers.Authorization = bearer;

      if (typeof window === "undefined") {
        failedRequest.response.config.headers.setCookie =
          resp.headers["set-cookie"];
      }

      return Promise.resolve();
    })
    .catch(() => Promise.reject());
});

export default proxyInstance;

import React from "react";
import { Container as Wrapper, Fab } from "@mui/material";
import { colors } from "src/config/colors";
import "nprogress/nprogress.css";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import paths from "src/router/paths";
import Disclaimer from "src/components/common/Disclaimer/Disclaimer";
import { ModalProvider } from "src/components/common/Layout/context/ModalContext";
import { useAuth } from "src/utils/hooks/common/useAuth";
import { useCookiePolicy } from "src/utils/hooks/common/useCookiePolicy";
import ScrollToTop from "src/components/common/ScrollToTop/ScrollToTop";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";
import Footer from "src/components/common/Footer/Footer";
import { useRXO } from "src/components/common/Layout/hooks/useRXO";
import FeedbackModal from "src/components/common/Layout/FeedbackModal/FeedbackModal";
import BottomMobileNavigation from "src/components/common/BottomMobileNavigation/BottomMobileNavigation";
import NewHeader from "src/components/common/Header/NewHeader";
// import Cookies from "js-cookie";

interface LayoutProps {
  withBottomNav?: boolean;
}

const TopProgressBar = dynamic(
  () => {
    return import("src/components/common/TopProgressBar/TopProgressBar");
  },
  { ssr: false }
);

const Layout: React.FC<LayoutProps> = ({ children, withBottomNav }) => {
  const { route } = useRouter();
  const routeOrdering = route.search(paths.ORDERING);

  useAuth();
  useCookiePolicy();
  useRXO();

  return (
    <ModalProvider>
      <Box id="back-to-top-anchor" />
      <TopProgressBar />
      <NewHeader />
      {/*{!Cookies.get("clickOldSite") && <OldSiteNotification />}*/}
      {/*<OldSiteNotification />*/}
      <Wrapper
        disableGutters
        maxWidth={false}
        component="main"
        sx={{
          backgroundColor: colors.gray,
          minHeight: "100%",
        }}
        {...(withBottomNav ? { style: { paddingBottom: "56px" } } : {})}
      >
        {children as any}
        <Disclaimer />
      </Wrapper>
      <Footer
        withBottomNav
        {...(routeOrdering > 0 ? { withBottomBasket: true } : {})}
      />
      <BottomMobileNavigation />
      <ScrollToTop>
        <Fab color="secondary" size="medium" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollToTop>
      <FeedbackModal />
    </ModalProvider>
  );
};

export default Layout;

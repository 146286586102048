import * as React from "react";

function SvgAlternationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={19}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.812 8.6a.589.589 0 00-.427-.169c-.35 0-.592.255-.62.654l-.013.193c-.025.362-.045.74-.107 1.102a7.711 7.711 0 01-3.477 5.23 7.754 7.754 0 01-6.247.932C3.123 15.473.714 11.741 1.323 7.86c.324-2.06 1.323-3.74 2.975-4.995 1.681-1.274 3.613-1.784 5.75-1.513.962.124 1.875.432 2.8.942.168.094.353.12.513.07a.592.592 0 00.358-.304c.164-.313.049-.658-.28-.843A9.572 9.572 0 009.021 0h-.017c-.189.025-.382.041-.567.062-.407.04-.826.082-1.233.164a8.903 8.903 0 00-5.816 4.025A8.979 8.979 0 00.123 7.576a8.956 8.956 0 00.156 3.692c1.023 4.057 4.677 6.787 8.734 6.787.583 0 1.18-.057 1.775-.172 2.002-.395 3.835-1.501 5.158-3.108a8.969 8.969 0 002.06-5.661c-.005-.214-.07-.39-.194-.514z"
        fill="#11193E"
        fillOpacity={0.8}
      />
      <path
        d="M11.146 7.013L10.01 8.148l-.731.732-1.854 1.854c-.263.259-.547.263-.806.008-.476-.473-.957-.958-1.422-1.427l-.172-.172c-.144-.144-.197-.296-.165-.469.046-.222.173-.37.379-.432a.508.508 0 01.538.144l.382.383.637.637c.288.283.633.296.896.033s.255-.617-.024-.9c-.37-.379-.711-.716-1.032-1.032a1.703 1.703 0 00-1.233-.502h-.189a.49.49 0 00-.12.016c-.694.14-1.17.551-1.376 1.193-.214.666-.066 1.286.423 1.792.374.386.756.769 1.13 1.143l.407.407c.395.399.86.608 1.336.608.477 0 .941-.21 1.344-.612l3.235-3.236 3.07-3.075.033-.032c.045-.046.09-.09.135-.128a.526.526 0 01.724.058c.218.234.189.534-.07.793-1.241 1.246-2.487 2.487-3.728 3.729L9.6 11.819l-.033.033a1.74 1.74 0 00-.143.156.556.556 0 00-.046.654.612.612 0 00.444.329c.025.004.05.004.078.004.177 0 .358-.083.514-.243 1.517-1.513 3.03-3.03 4.542-4.543l1.036-1.035c.045-.046.09-.09.135-.132.111-.107.226-.222.333-.337a1.76 1.76 0 00-.074-2.487c-.699-.666-1.796-.637-2.499.066-.92.908-1.85 1.833-2.741 2.73z"
        fill="#11193E"
        fillOpacity={0.8}
      />
    </svg>
  );
}

export default SvgAlternationIcon;

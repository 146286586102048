import * as React from "react";

function SvgHistoryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#C8C8C8">
        <path d="M8.742 4.15A8.5 8.5 0 113.5 12 .75.75 0 002 12a10 10 0 102.413-6.514V4.5a.75.75 0 10-1.5 0v4.378l3.71-.617a.75.75 0 10-.246-1.48l-1.242.207A8.5 8.5 0 018.742 4.15z" />
        <path d="M12.5 7.5a.75.75 0 00-1.5 0V12a.75.75 0 00.262.57l3.5 3a.75.75 0 10.976-1.14L12.5 11.656V7.5z" />
      </g>
    </svg>
  );
}

export default SvgHistoryIcon;

import React from "react";
import NextLink from "src/components/common/Links/Link";
import FooterNavItem from "src/components/common/Footer/FooterAccordion/FooterNavItem";
import { colors } from "src/config/colors";
import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import paths from "src/router/paths";
import { ModalType } from "src/utils/hooks/state/useModal";
import { useModalContext } from "src/components/common/Layout/context/ModalContext";
import { Theme } from "@mui/system";
import { createUrl } from "src/utils/helpers/url/createUrl";
import { DEFAULT_CITY } from "src/utils/constant/constant";
import { shallowEqual, useSelector } from "react-redux";
import { selectCity } from "src/redux/slice/citySlice";

const _links = {
  userInfo: [
    { title: "Частые вопросы", href: paths.FAQ },
    { title: "Задать вопрос", href: paths.FEEDBACK },
  ],
  info: [
    { title: "О нас", href: paths.ABOUT },
    { title: "Контакты", href: paths.CONTACTS },
    { title: "Подключить аптеку", href: paths.ADD_MY_PHARM },
    { title: "Персональные данные", href: paths.POLICY_PD },
    { title: "Пользовательское соглашение", href: paths.USER_AGREEMENT },
    { title: "Дисклеймер", href: paths.DISCLAIMER },
    { title: "Политика cookie", href: paths.COOKIE },
  ],
};

const FooterNavList: React.FC = () => {
  const { openModal } = useModalContext();

  const cityInfo = useSelector(selectCity, shallowEqual);
  const currentCity = cityInfo.slug === "" ? DEFAULT_CITY : cityInfo.slug;

  const isLargeScreenDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  return (
    <Grid container spacing={{ xs: 0, md: 4 }}>
      <Grid item xs={12} md={5}>
        <FooterNavItem
          disableExpandIcon
          isLargeScreenDown={isLargeScreenDown}
          disableDivider={isLargeScreenDown}
          isDark
          title="Поиск"
          itemLink={
            <>
              <NextLink
                sx={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "16px",
                  lineHeight: "28px",
                  ":hover": {
                    textDecoration: "underline",
                    color: colors.white,
                  },
                }}
                href={createUrl([currentCity, paths.CATALOG])}
              >
                Каталог
              </NextLink>
            </>
          }
        />
        <FooterNavItem
          disableExpandIcon
          isLargeScreenDown={isLargeScreenDown}
          disableDivider={isLargeScreenDown}
          isDark
          title="Покупателю"
          itemLink={
            <>
              <Typography
                component="a"
                fontSize={16}
                lineHeight="32px"
                onClick={() => openModal(ModalType.CHECK_ORDER_STATUS)}
                sx={{
                  cursor: "pointer",
                  color: "rgba(255, 255, 255, 0.7)",
                  ":hover": {
                    textDecoration: "underline",
                    color: colors.white,
                  },
                }}
              >
                Узнать статус заказа
              </Typography>
              <Typography
                component="a"
                fontSize={16}
                lineHeight="32px"
                href={createUrl([currentCity, paths.APTEKI])}
                sx={{
                  color: "rgba(255, 255, 255, 0.7)",
                  cursor: "pointer",
                  textDecoration: "none",
                  ":hover": {
                    textDecoration: "underline",
                    color: colors.white,
                  },
                }}
              >
                Аптеки
              </Typography>
              <Typography
                component="a"
                fontSize={16}
                lineHeight="32px"
                href={"/" + paths.BLOG}
                sx={{
                  color: "rgba(255, 255, 255, 0.7)",
                  cursor: "pointer",
                  textDecoration: "none",
                  ":hover": {
                    textDecoration: "underline",
                    color: colors.white,
                  },
                }}
              >
                Блог
              </Typography>
              {_links.userInfo.map((link) => (
                <NextLink
                  key={link.title}
                  sx={{
                    color: "rgba(255, 255, 255, 0.7)",
                    fontSize: "16px",
                    lineHeight: "32px",
                    ":hover": {
                      textDecoration: "underline",
                      color: colors.white,
                    },
                  }}
                  href={"/" + link.href}
                >
                  {link.title}
                </NextLink>
              ))}
            </>
          }
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <FooterNavItem
          disableExpandIcon
          isLargeScreenDown={isLargeScreenDown}
          disableDivider={isLargeScreenDown}
          isDark
          title="О компании"
          itemLink={
            <>
              {_links.info.map((link) => (
                <NextLink
                  key={link.title}
                  sx={{
                    color: "rgba(255, 255, 255, 0.7)",
                    ":hover": {
                      textDecoration: "underline",
                      color: colors.white,
                    },
                    fontSize: "16px",
                    lineHeight: "32px",
                  }}
                  href={"/" + link.href}
                >
                  {link.title}
                </NextLink>
              ))}
              <NextLink
                href={paths.MAP_AGREEMENT}
                target="_blank"
                rel="noreferrer"
                sx={{
                  textDecoration: "none",
                  color: "rgba(255, 255, 255, 0.7)",
                  ":hover": {
                    textDecoration: "underline",
                    color: colors.white,
                  },
                }}
              >
                Условия использования Яндекс карт
              </NextLink>
            </>
          }
        />
        <Divider
          sx={{ bgcolor: "rgba(255, 255, 255, 0.2)", display: { lg: "none" } }}
        />
      </Grid>
    </Grid>
  );
};
export default FooterNavList;

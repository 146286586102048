import * as React from "react";

function SvgBlogIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9a3 3 0 013-3h8a3 3 0 013 3v2a3 3 0 01-3 3h-8a3 3 0 01-3-3V9zm3-1a1 1 0 00-1 1v2a1 1 0 001 1h8a1 1 0 001-1V9a1 1 0 00-1-1h-8z"
        fill="#333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 9H9a3 3 0 00-3 3v9h7.035l3.298-5.534 2 7.339L20.51 20h5.258a1 1 0 110 2H21.49l-4.034 5.195-1.815-6.661L14.171 23H6v16a3 3 0 003 3h20a3 3 0 003-3V12a3 3 0 00-3-3h-4v2a2 2 0 01-2 2h-8a2 2 0 01-2-2V9zm-2 21a1 1 0 011-1h14a1 1 0 110 2H12a1 1 0 01-1-1zm1 4a1 1 0 100 2h14a1 1 0 100-2H12zM39 15a3 3 0 00-3 3v3h6v-3a3 3 0 00-3-3zm3 8h-6v15l3 4 3-4V23z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgBlogIcon;

import * as React from "react";

function SvgLinkedInIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={42}
      height={42}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={42} height={42} rx={21} fill="#11193E" />
      <path
        d="M29.999 27.756h-3.646v-.361c0-1.752.007-3.498-.007-5.25 0-.393-.04-.799-.139-1.179-.332-1.23-1.753-1.7-2.822-.947-.631.451-.877 1.07-.87 1.817.006 1.836 0 3.672 0 5.507v.4h-3.613V17.146h3.6v1.463c.126-.142.186-.213.239-.284.956-1.256 2.277-1.597 3.791-1.391 1.441.2 2.477.96 3.002 2.28.226.56.312 1.172.465 1.765v6.777zM13 13.52c.505-1.25 1.448-1.746 2.696-1.424.877.225 1.454.979 1.401 1.835-.046.877-.67 1.56-1.56 1.714-1.236.206-1.973-.206-2.537-1.417v-.709zM13.232 17.153h3.606v10.596h-3.606V17.153z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLinkedInIcon;

import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { confirmPinCode } from "src/models/auth/confirmPinCode";
import { getMe } from "src/models/user/getMe";
import { logout } from "src/models/auth/logout";
import { toastAlert } from "src/components/common/SnackbarAlert/toastAlert";
import { AppState } from "src/redux/store";
import { DISABLE_CACHE_AXIOS } from "src/utils/constant/constant";

export enum AuthStates {
  IDLE = "idle",
  LOADING = "loading",
}

export const fetchUser = createAsyncThunk("auth/me", async (_, thunkAPI) => {
  try {
    const response = await getMe(DISABLE_CACHE_AXIOS);

    return {
      me: { ...response.data },
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const loginWithCode = createAsyncThunk(
  "auth/loginWithCode",
  async (credentials: { phone: string; code: string }, thunkAPI) => {
    try {
      const authResponse = await confirmPinCode(credentials);
      const aboutUser = await getMe(DISABLE_CACHE_AXIOS);

      return {
        me: { ...aboutUser.data },
        isGreeting: authResponse.data.needGreeting,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      const response = await logout();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export enum AuthStage {
  noAuth = "unauthorized",
  auth = "authorized",
  greeting = "greeting",
  secretCode = "secret-code",
}

export interface AuthSliceState {
  loading: AuthStates;
  isGreeting?: boolean;
  authStage: AuthStage;
  me?: {
    name?: string;
    email?: string;
    phone?: string;
  };
  error?: SerializedError;
}

const internalInitialState: AuthSliceState = {
  loading: AuthStates.IDLE,
  isGreeting: false,
  authStage: AuthStage.noAuth,
  me: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState: internalInitialState,
  reducers: {
    resetAuth: () => internalInitialState,
    setSecretStage: (state) => {
      state.authStage = AuthStage.secretCode;
    },
    setAuth: (state) => {
      state.isGreeting = false;
      state.authStage = AuthStage.auth;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginWithCode.fulfilled, (state, action: any) => {
      state.me = action.payload.me;
      state.authStage = AuthStage.auth;
      state.isGreeting = action.payload.isGreeting;
      state.loading = AuthStates.IDLE;
      toastAlert("Успешная авторизация", "success", {
        autoClose: 5000,
      });
    });
    builder.addCase(loginWithCode.rejected, (state, action) => {
      state = { ...internalInitialState, error: action.error };
      toastAlert("Неверный код", "error", { autoClose: 5000 });
      // throw new Error(action.error.message);
    });
    builder.addCase(logoutUser.pending, (state) => {
      state.loading = AuthStates.LOADING;
    });
    builder.addCase(logoutUser.fulfilled, (_state) => internalInitialState);
    builder.addCase(loginWithCode.pending, (state) => {
      state.loading = AuthStates.LOADING;
    });

    builder.addCase(fetchUser.rejected, (state, action) => {
      state = { ...internalInitialState, error: action.error };
      // throw new Error(action.error.message)
    });
    builder.addCase(fetchUser.fulfilled, (state, action: any) => {
      state.me = action.payload.me;
      state.authStage = AuthStage.auth;
    });
  },
});

export const authData = (state: AppState) => state.authReducer;
export const userInfoSelector = (state: AppState) => state.authReducer.me;

export const authActions = authSlice.actions;

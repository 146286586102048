import React, { useState } from "react";
import FeedbackButton from "src/components/common/FeedbackButton/FeedbackButton";
import FeedbackFormModal from "src/components/FeedbackFormModal/FeedbackFormModal";
import { useModalContext } from "src/components/common/Layout/context/ModalContext";
import { ModalType } from "src/utils/hooks/state/useModal";

const FeedbackModal: React.FC = () => {
  const [rating, setRating] = useState<number | null>(null);
  const { modalType, openModal, closeModal } = useModalContext();
  return (
    <>
      <FeedbackButton
        onClick={() => {
          openModal(ModalType.FEEDBACK);
        }}
      />
      <FeedbackFormModal
        rating={rating}
        setRating={setRating}
        isOpen={modalType === ModalType.FEEDBACK}
        closeModal={closeModal}
      />
    </>
  );
};

export default FeedbackModal;

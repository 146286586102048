export const colors = {
  yellow: "#F7E64D",
  darkYellow: "rgb(172, 161, 53)",
  gray: "#F5F5F5",
  gray2: "#505050",
  gray3: "#909090",
  darkGray: "rgba(17, 25, 62, 0.5)",
  shadowGray: "#F8F8F8",
  transparentGray: "#CCCCCC50",
  blue: "#11193E",
  blue2: "#3C56CB",
  shadowBlue: "rgba(17, 25, 62, 0.7)",
  darkBlue: "#101839",
  red: "#FA1647",
  darkRed: "#AA203E",
  white: "#FFFFFF",
  lightGray: "rgba(17, 25, 62, 0.1)",
  opacityWhite: "rgba(255,255,255,0.1)",
  transparentWhite: "rgba(255, 255, 255, 0.5)",
  fadedYellow: "#FFF8D0",
  black: "#000000",
  black2: "#20201C",
  green: "#41983A",
};

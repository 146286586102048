import * as React from "react";

function SvgArrowBack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.222 12.532a.75.75 0 010-1.06l5.5-5.501a.75.75 0 011.061 1.06l-4.22 4.221h12.685a.75.75 0 110 1.5H6.563l4.22 4.22a.75.75 0 01-1.06 1.061l-5.501-5.5z"
        fill="#BCBCB5"
      />
    </svg>
  );
}

export default SvgArrowBack;

import * as React from "react";

function SvgHomeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 37a1 1 0 011-1h36a1 1 0 110 2H5a1 1 0 01-1-1z"
        fill="#333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.16V36h24V23.16l-12-6.857-12 6.858zM10 22v16h28V22l-14-8-14 8z"
        fill="#333"
      />
      <path
        d="M28 25h6v11h-6V25zM14 25h11v6H14v-6zM32 14.818V12h3v5l-3-2.182z"
        fill="#333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 12a1 1 0 011-1h3a1 1 0 011 1v5a1 1 0 01-1.588.809l-3-2.182a1 1 0 01-.412-.809V12zm2 1v1.309l1 .727V13h-1z"
        fill="#333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.493 10.138a1 1 0 011.014 0l17.862 10.507-1.014 1.724L24 12.16 6.645 22.37l-1.014-1.724 17.862-10.507z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgHomeIcon;

import apiInstance from "src/config/api/apiInstance";
import proxyInstance from "src/config/api/proxyInstance";

export const setInstanceByEnvType = () => {
  if (typeof window === "undefined") {
    return apiInstance;
  } else {
    return proxyInstance;
  }
};

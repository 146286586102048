export const DEFAULT_CITY = "moskva";
// город по умолчанию
export const DEBOUCE_DELAY = 300;
// задержка запросов
export const MIN_CHAR_LENGTH = 3;
// минимальное количество символов для выполнения запроса
export const VALIDATION_EMAIL =
  /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/;

export const DISABLE_CACHE_AXIOS = {
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

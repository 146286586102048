import * as React from "react";

function SvgCatalogIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 2H3.875A1.877 1.877 0 002 3.875V9.5c0 1.034.841 1.875 1.875 1.875H9.5A1.877 1.877 0 0011.375 9.5V3.875A1.877 1.877 0 009.5 2zm.625 7.5c0 .344-.28.625-.625.625H3.875A.626.626 0 013.25 9.5V3.875c0-.344.28-.625.625-.625H9.5c.345 0 .625.28.625.625V9.5zm10-7.5H14.5a1.877 1.877 0 00-1.875 1.875V9.5c0 1.034.841 1.875 1.875 1.875h5.625A1.877 1.877 0 0022 9.5V3.875A1.877 1.877 0 0020.125 2zm.625 7.5c0 .344-.28.625-.625.625H14.5a.626.626 0 01-.625-.625V3.875c0-.344.28-.625.625-.625h5.625c.345 0 .625.28.625.625V9.5zm-.625 3.125H14.5a1.877 1.877 0 00-1.875 1.875v5.625c0 1.034.841 1.875 1.875 1.875h5.625A1.877 1.877 0 0022 20.125V14.5a1.877 1.877 0 00-1.875-1.875zm.625 7.5c0 .344-.28.625-.625.625H14.5a.626.626 0 01-.625-.625V14.5c0-.344.28-.625.625-.625h5.625c.345 0 .625.28.625.625v5.625zm-11.25-7.5H3.875A1.877 1.877 0 002 14.5v5.625C2 21.159 2.841 22 3.875 22H9.5a1.877 1.877 0 001.875-1.875V14.5A1.877 1.877 0 009.5 12.625zm.625 7.5c0 .344-.28.625-.625.625H3.875a.626.626 0 01-.625-.625V14.5c0-.344.28-.625.625-.625H9.5c.345 0 .625.28.625.625v5.625z"
        fill="#20201C"
      />
    </svg>
  );
}

export default SvgCatalogIcon;

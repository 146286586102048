import { toast, ToastOptions } from "react-toastify";
import { ToastContent, TypeOptions } from "react-toastify/dist/types";
import CookieAlert from "src/components/common/SnackbarAlert/CookieAlert";
import OrderingAlert from "src/components/common/SnackbarAlert/OrderingAlert";
import React from "react";

export const toastAlert = (
  msg: ToastContent,
  type: TypeOptions,
  options?: ToastOptions
) => {
  return toast[type](msg, { draggable: true, closeOnClick: true, ...options });
};

export const toastCookiePolicy = (onClick: () => void, clickNav?: any) => {
  return toast(<CookieAlert onClick={onClick} clickNav={clickNav} />, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: false,
    style: {
      background: "#101839",
      cursor: "auto",
      padding: "4px",
    },
    closeOnClick: false,
    closeButton: false,
  });
};

export const toastOrderingAlert = (onClick: () => void) => {
  return toast(<OrderingAlert onClick={onClick} />, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 8000,
    style: {
      background: "#101839",
      cursor: "auto",
    },
    closeButton: false,
    hideProgressBar: true,
  });
};

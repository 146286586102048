import React, { useState } from "react";
import { Box, Paper, TextField, Typography } from "@mui/material";
import styled from "src/components/common/Footer/styled";
import { colors } from "src/config/colors";
import { SubmitHandler, useForm } from "react-hook-form";
import { signUpForNews } from "src/models/signUpForNews/signUpForNews";
import SubmitButton from "src/components/common/Buttons/SubmitButton";
import CheckIcon from "@mui/icons-material/Check";

type Inputs = {
  email: string;
};

const SubscriptionNews: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const isMadeRequest = success || error || loading;

  const clearStatuses = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!loading) {
      setSuccess(false);
      setError(false);
    }
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!success) {
      setLoading(true);
      signUpForNews({ email: data.email })
        .then(() => {
          setSuccess(true);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        position: "relative",
        display: "flex",
        minWidth: { xs: "100%", lg: "384px" },
        maxWidth: { xs: "100%", lg: "384px" },
        height: "fit-content",
        padding: success
          ? { xs: "40px", md: "60px" }
          : { xs: "16px", md: "24px" },
        flexDirection: "column",
        borderRadius: "8px",
      }}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      {success ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "56px",
              height: "56px",
              backgroundColor: colors.green,
              borderRadius: "50%",
              marginX: "auto",
              mb: 2,
            }}
          >
            <CheckIcon
              sx={{
                color: colors.white,
              }}
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { xs: "14px", md: "18px" },
              lineHeight: "25px",
            }}
          >
            Поздравляем, вы подписаны на самые важные новости от ЦЕНЫвАПТЕКАХ
          </Typography>
        </>
      ) : (
        <>
          <Typography
            component="div"
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              lineHeight: { xs: "22px", md: "28px" },
              color: colors.black2,
              fontWeight: 500,
              mb: 4,
            }}
          >
            Будьте в курсе полезной и актуальной информации от ЦЕНЫвАПТЕКАХ
          </Typography>
          <TextField
            error={!!errors.email}
            helperText={errors.email?.message}
            sx={styled.textField}
            placeholder="Введите ваш E-mail"
            {...register("email", {
              required: "Введите адрес электронной почты",
              pattern: {
                value: /.+@.+\..+/i,
                message: "Неверный формат электронной почты",
              },
            })}
          />
          <SubmitButton
            title="Отправить"
            sx={{
              width: "100%",
              height: "48px",
              padding: "14px 10px",
            }}
            typographySx={{
              textTransform: "none",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 400,
              color: colors.black2,
            }}
            disabled={!!errors.email}
            onClick={isMadeRequest ? clearStatuses : undefined}
            type={isMadeRequest ? "button" : "submit"}
          />
        </>
      )}
    </Paper>
  );
};

export default SubscriptionNews;

import React from "react";
import { Box, InputBase, Paper } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import styled from "./styled";
import { colors } from "src/config/colors";
import HeaderSearchIcon from "src/assets/icons/HeaderSearchIcon";

interface InputWithButtonProps {
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  paperSx?: SxProps<Theme>;
  inputSx?: SxProps<Theme>;
}

const InputWithButton: React.FC<InputWithButtonProps> = ({
  onFocus,
  paperSx,
  inputSx,
}) => {
  return (
    <>
      <Paper sx={{ ...styled.searchInput, ...paperSx }} onFocus={onFocus}>
        <InputBase
          sx={{ ...styled.input, ...inputSx }}
          placeholder="Введите название препарата/вещества"
          inputProps={{ "aria-label": "search medical products" }}
        />
      </Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "8px 16px", lg: "12px 32px" },
          height: { xs: "40px", lg: "56px" },
          borderRadius: "0px 8px 8px 0px",
          border: "1px solid #F7E64D",
          backgroundColor: colors.yellow,
          cursor: "pointer",
          ":hover": {
            backgroundColor: "#ECD50D",
            borderColor: "#ECD50D",
          },
        }}
      >
        <HeaderSearchIcon />
      </Box>
    </>
  );
};

export default InputWithButton;

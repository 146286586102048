import React from "react";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import Image from "next/image";
import Typography from "@mui/material/Typography";
import { colors } from "src/config/colors";
import { CrossClose } from "src/assets/icons/index";
import FeedbackForm from "src/components/FeedbackFormModal/components/FeedbackForm";
import Container from "src/components/common/Layout/Container";
import big_smile1 from "src/assets/big_smile1.png";
import big_smile2 from "src/assets/big_smile2.png";
import big_smile3 from "src/assets/big_smile3.png";
import big_smile4 from "src/assets/big_smile4.png";
import big_smile5 from "src/assets/big_smile5.png";

interface FeedbackFormProps {
  closeModal: () => void;
  isOpen: boolean;
  rating: number | null;
  setRating: React.Dispatch<React.SetStateAction<number | null>>;
}

const smilesRating = [
  {
    altImage: "smile1",
    srcImage: big_smile1,
    valueRating: 1,
  },
  {
    altImage: "smile2",
    srcImage: big_smile2,
    valueRating: 2,
  },
  {
    altImage: "smile3",
    srcImage: big_smile3,
    valueRating: 3,
  },
  {
    altImage: "smile4",
    srcImage: big_smile4,
    valueRating: 4,
  },
  {
    altImage: "smile5",
    srcImage: big_smile5,
    valueRating: 5,
  },
];

const FeedbackFormModal: React.FC<FeedbackFormProps> = ({
  closeModal,
  isOpen,
  rating,
  setRating,
}) => {
  return (
    <Dialog sx={{ zIndex: 3000 }} closeAfterTransition open={isOpen}>
      <IconButton
        onClick={closeModal}
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 1301,
        }}
      >
        <CrossClose color="#11193E" />
      </IconButton>
      <Container paddingTop={5} paddingBottom={2}>
        <Typography
          component="h3"
          sx={{
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: { xs: "12px", sm: "14px" },
            mb: "28px",
            fontWeight: 700,
            color: colors.blue,
            lineHeight: "15px",
          }}
        >
          Насколько просто найти товар и оформить заказ на сайте ценываптеках?
        </Typography>
        <Stack justifyContent="center" direction="row" spacing={2.5}>
          {smilesRating.map((smile) => (
            <Box
              key={smile.valueRating}
              onClick={() => {
                setRating(smile.valueRating);
              }}
              sx={{
                width: "49px",
                height: "49px",
                borderRadius: "50%",
                cursor: "pointer",
                opacity: rating
                  ? rating === smile.valueRating
                    ? "100%"
                    : "50%"
                  : "100%",
                ":hover": {
                  transform: "scale(1.2)",
                },
              }}
            >
              <Image
                alt={smile.altImage}
                width="49px"
                height="49px"
                src={smile.srcImage.src}
              />
            </Box>
          ))}
        </Stack>
        <Typography
          color={colors.darkGray}
          mt="15px"
          textAlign="center"
          fontSize={{ xs: "12px", sm: "14px" }}
        >
          ВЫБЕРИТЕ ВАРИАНТ
        </Typography>
      </Container>
      <Container
        wrapperSx={{
          height: rating ? "550px" : "0px",
          overflow: !rating ? "hidden" : { xs: "revert", sm: "hidden" },
          transition: ".4s all ease",
          paddingBottom: rating ? "10px" : "0px",
        }}
        bgColor={colors.yellow}
        paddingTop={2}
        paddingBottom={3}
      >
        {rating && (
          <FeedbackForm
            setRating={setRating}
            rating={rating}
            closeModal={closeModal}
          />
        )}
      </Container>
    </Dialog>
  );
};

export default FeedbackFormModal;

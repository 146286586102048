import proxyInstance from "src/config/api/proxyInstance";

interface SendFeedBackSchema {
  name: string;
  phone: string;
  msg: string;
  rating: number;
}

export const sendFeedback = (data: SendFeedBackSchema) =>
  proxyInstance.post<SendFeedBackSchema>("users/send_feedback", null, {
    params: data,
  });

declare global {
  interface Window {
    ym?: any;
  }
}

export enum EnumMetrikActions {
  goToTheProductPage = "go_to_the_product_page",
  bookingByList = "booking_by_list",
  bookingByMap = "booking_by_map",
  goToTheBasketPage = "go_to_the_basket_page",
  goToTheNtpPage = "go_to_the_ntp_page",
  pressButtonMakeReservationInBasket = "press_button_make_reservation_in_basket",
  goToHistoryItemFromHistory = "go_to_history_item_from_history",
  pressButtonMakeReservationInConfirmOrder = "press_button_make_reservation_in_confirm_order",
  sucessfullOrderReservation = "sucessfull_order_reservation",
  notSucessfullOrderReservation = "not_sucessfull_order_reservation",
  pressBtnCloseInMap = "press_btn_close_in_map",
  goToBasketFromAlert = "go_to_basket_from_alert",
  showAlertGoodsAddedToBasket = "show_alert_goods_added_to_basket",
  goToBasketFromHeader = "go_to_basket_from_header",
  goToBrandFromSearch = "go_to_brand_from_search",
  goToAnalogFromSearch = "go_to_analog_from_search",
  goToHistoryItemFromSearch = "go_to_history_item_from_search",
  pressBtnMoreInfoInOffersPage = "press_btn_more_info_in_offers_page",
  pressBtnPreorderInOffersPage = "press_btn_preorder_in_offers_page",
  pressBtnOrderInOffersPage = "press_btn_order_in_offers_page",
  fromSearchChooseHistoryItem = "from_search_choose_history_item",
  clickQuickOrderOnProductPage = "click_quick_order_on_product_page",
  clickShowOffersBtnOnProductPage = "click_show_offers_btn_on_product_page",
  anotherProductFormBtnOnProductPage = "click_another_product_form_btn_on_product_page",
  clickMainNavigationBtnOnProductPage = "click_main_navigation_btn_on_product_page",
  clickInstructionNavigationBtnOnProductPage = "click_instruction_navigation_btn_on_product_page",
  clickAnalogsNavigationBtnOnProductPage = "click_analogs_navigation_btn_on_product_page",
  clickAnyAnalogBtnOnProductPage = "click_any_analog_btn_on_product_page",
  clickOnFiltersBtnOnOffersMap = "click_on_filters_btn_on_offers_map",
  clickOnShowOffersListBtnOnOffersMap = "click_on_show_offers_list_btn_on_offers_map",
  clickOnReturnBackBtnOnOffersList = "click_on_return_back_btn_on_offers_list",
  clickOnAnySortingOnOffersList = "click_on_any_sorting_on_offers_list",
  clickOnSearchBtnInFooterMenu = "click_on_search_btn_in_footer_menu",
  clickOnCartBtnInFooterMenu = "click_on_cart_btn_in_footer_menu",
  clickOnReviewBtnInFooterMenu = "click_on_review_btn_in_footer_menu",
  clickOnAuthBtnInFooterMenu = "click_on_auth_btn_in_footer_menu",
  clickOnOrdersBtnInFooterMenu = "click_on_orders_btn_in_footer_menu",
}

export const sendCustomYandexMetrik = (value: string) => {
  return window?.ym && window.ym(89495051, "reachGoal", value);
};

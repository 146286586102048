import React from "react";
import { Box, Container as Wrapper, Divider } from "@mui/material";
import { colors } from "src/config/colors";
import Container from "src/components/common/Layout/Container";
import Typography from "@mui/material/Typography";
import FooterNavList from "src/components/common/Footer/FooterAccordion/FooterNavList";
import useMediaQuery from "@mui/material/useMediaQuery";
import SubscriptionNews from "src/components/common/SubscriptionNews/SubscriptionNews";
import { Theme } from "@mui/system";

interface FooterProps {
  withBottomNav?: boolean;
  withBottomBasket?: boolean;
}

const Footer: React.FC<FooterProps> = ({ withBottomBasket, withBottomNav }) => {
  const isLargeScreenDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  return (
    <>
      <Box
        component="footer"
        {...(withBottomNav
          ? { sx: { paddingBottom: isLargeScreenDown ? "0px" : "60px" } }
          : {})}
        {...(withBottomBasket
          ? { sx: { paddingBottom: isLargeScreenDown ? "0px" : "98px" } }
          : {})}
      >
        <Container
          isWithPaper
          sx={{ flexDirection: { display: "flex", xs: "column", tb: "row" } }}
        >
          {/*<FooterSpecificationList />*/}
        </Container>
        <Container bgColor={colors.darkBlue} paddingTop={4}>
          <Wrapper disableGutters>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", lg: "row" },
                justifyContent: "space-between",
              }}
            >
              <FooterNavList />
              <SubscriptionNews />
            </Box>
            <Divider sx={{ bgcolor: "rgba(255, 255, 255, 0.2)" }} />
            <Typography
              sx={{ paddingY: "20px", display: "block" }}
              component="span"
              fontSize={14}
              textAlign="center"
              color={colors.white}
            >
              © 2014-2024 ЦЕНЫвАПТЕКАХ <br /> Вся информация, размещенная на
              сайте, носит ознакомительный характер и не может служить заменой
              очной консультации врача
            </Typography>
          </Wrapper>
        </Container>
      </Box>
    </>
  );
};

export default Footer;

import Cookies from "js-cookie";

export const DAYS_FOR_EXPIRE = 365;

export const setCityCookie = (slug: string, cityName: string) => {
  Cookies.set("slug", slug, { expires: DAYS_FOR_EXPIRE });
  Cookies.set("cityName", cityName);
};

export const getCityCookie = () => {
  return { slug: Cookies.get("slug"), name: Cookies.get("cityName") };
};
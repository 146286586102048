import { useEffect } from "react";
import { toastCookiePolicy } from "src/components/common/SnackbarAlert/toastAlert";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { DAYS_FOR_EXPIRE } from "src/utils/helpers/cookie/setCityCookie";
import { toast } from "react-toastify";

export const useCookiePolicy = () => {
  const router = useRouter();

  const onClickAgreement = () => {
    Cookies.set("cookieAgree", "true", { expires: DAYS_FOR_EXPIRE });
    toast.dismiss();
  };

  const onClickHowItWork = () => {
    router.push("/cookie");
  };

  useEffect(() => {
    if (!Cookies.get("cookieAgree"))
      toastCookiePolicy(onClickAgreement, onClickHowItWork);
  }, []);
};

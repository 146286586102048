import * as React from "react";

function SvgCopyBufferIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1.5h7a.5.5 0 01.5.5H11a2 2 0 00-2-2H2a2 2 0 00-2 2v7a2 2 0 002 2V9.5a.5.5 0 01-.5-.5V2a.5.5 0 01.5-.5z"
        fill="#101839"
      />
      <rect
        x={3.75}
        y={3.75}
        width={9.5}
        height={9.5}
        rx={1.25}
        stroke="#101839"
        strokeWidth={1.5}
      />
    </svg>
  );
}

export default SvgCopyBufferIcon;

import * as React from "react";

function SvgCurrentPoint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#3C56CB">
        <path d="M11.105 19.792a.472.472 0 00.397.21.481.481 0 00.396-.21c1.126-1.664 2.786-3.72 3.943-5.81.924-1.672 1.373-3.096 1.373-4.357A5.588 5.588 0 0015.54 5.65a5.766 5.766 0 00-8.075 0A5.589 5.589 0 005.79 9.625c0 1.26.45 2.686 1.374 4.357 1.155 2.088 2.819 4.15 3.942 5.81zm.397-14.855c2.624 0 4.761 2.102 4.761 4.688 0 1.099-.412 2.378-1.26 3.908-.995 1.801-2.418 3.629-3.501 5.165-1.083-1.536-2.506-3.364-3.502-5.165-.848-1.53-1.26-2.808-1.26-3.909 0-2.584 2.137-4.688 4.762-4.688z" />
        <path d="M11.502 12.438c.758-.001 1.484-.298 2.02-.825a2.794 2.794 0 00.837-1.988 2.794 2.794 0 00-.838-1.988 2.883 2.883 0 00-4.037 0 2.794 2.794 0 00-.838 1.987c0 .746.301 1.461.837 1.989a2.883 2.883 0 002.02.825zm0-4.688c.505 0 .99.198 1.346.55.357.351.558.828.559 1.325 0 .497-.201.974-.559 1.325a1.921 1.921 0 01-2.692 0 1.863 1.863 0 01-.558-1.325c0-.497.201-.974.558-1.325a1.921 1.921 0 011.346-.55z" />
      </g>
    </svg>
  );
}

export default SvgCurrentPoint;

import React from "react";
import { Button } from "@mui/material";
import { SxProps } from "@mui/system";

interface ButtonWithIconProps {
  width?: number;
  height?: number;
  sx?: SxProps;
  onClick?: () => void;
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  children,
  width,
  height,
  sx,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="secondary"
      size="small"
      sx={{
        width: width ? `${width}px` : null,
        height: height ? `${height}px` : null,
        minWidth: "auto",
        ...sx,
      }}
      disableElevation
    >
      {children}
    </Button>
  );
};

export default ButtonWithIcon;

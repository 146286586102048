import React, { useEffect } from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import { EmotionCache } from "@emotion/cache";
import { theme } from "src/config/theme/theme";
import Layout from "src/components/common/Layout/Layout";
import { wrapper } from "src/redux/store";
import createEmotionCache from "src/utils/helpers/cache/createEmotionCache";
import { toast } from "react-toastify";
import { toastConfigure } from "src/config/toastConfigure";
import "react-toastify/dist/ReactToastify.min.css";
import "./style/yandexMap.css";
import "./style/sliderDot.css";
import "./style/nprogress.css";
import "./style/global.css";
import { useRouter } from "next/router";

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

toast.configure(toastConfigure);

export default wrapper.withRedux(function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const router = useRouter();

  useEffect(() => {
    router.prefetch = async () => {};
  }, [router]);

  return (
    <React.Fragment>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>CVA</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ThemeProvider>
      </CacheProvider>
    </React.Fragment>
  );
});

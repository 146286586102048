import * as React from "react";

function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={5}
      height={8}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.22.22a.75.75 0 000 1.06l2.47 2.47L.22 6.22a.75.75 0 101.06 1.06l3.53-3.53L1.28.22a.75.75 0 00-1.06 0z"
      />
    </svg>
  );
}

export default SvgArrowRight;

import React from "react";
import { FormControl, InputBaseComponentProps, TextField } from "@mui/material";
import { UseFormRegisterReturn } from "react-hook-form";
import { SxProps, Theme } from "@mui/system";

export interface FieldProps {
  label?: string;
  register?: UseFormRegisterReturn;
  error?: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  fullWidth?: boolean;
  rows?: number;
  multiline?: boolean;
  autoFocus?: boolean;
  value?: string;
  inputProps?: InputBaseComponentProps;
  disabled?: boolean;
  maxRows?: number;
  sx?: SxProps<Theme>;
  formControlSx?: SxProps<Theme>;
}

const Field: React.FC<FieldProps> = ({
  label,
  register = {},
  error,
  type,
  defaultValue,
  fullWidth,
  rows,
  multiline,
  autoFocus,
  inputProps,
  placeholder,
  value,
  disabled,
  maxRows,
  sx,
  formControlSx,
}) => (
  <FormControl sx={formControlSx}>
    <TextField
      sx={sx}
      InputLabelProps={{ shrink: true }}
      placeholder={placeholder}
      defaultValue={defaultValue}
      margin="dense"
      size="small"
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      {...register}
      error={!!error}
      helperText={error}
      type={type}
      rows={rows}
      multiline={multiline}
      autoFocus={autoFocus}
      inputProps={inputProps}
      value={value}
      disabled={disabled}
      maxRows={maxRows}
    />
  </FormControl>
);

export default Field;

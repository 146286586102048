import React, { useEffect, useMemo } from "react";
import dynamic from "next/dynamic";
import AppBar from "@mui/material/AppBar";
import { Box, Container as Wrapper } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { colors } from "src/config/colors";
import { ModalType } from "src/utils/hooks/state/useModal";
import { baseUrlGeneration } from "src/utils/helpers/url/baseUrlGeneration";
import Image from "next/image";
import Logo from "public/assets/icons/logo.png";
import Typography from "@mui/material/Typography";
import LocationButton from "src/components/common/Header/components/LocationButton";
import SearchButton from "src/components/common/Header/components/SearchButton";
import { createUrl } from "src/utils/helpers/url/createUrl";
import paths from "src/router/paths";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectCity, validationCity } from "src/redux/slice/citySlice";
import { useRouter } from "next/router";
import { useQuery } from "src/utils/hooks/common/useQuery";
import { selectCount } from "src/redux/slice/basketSlice";
import { calcProductInBasket } from "src/utils/helpers/calculate/calcProductInBasket";
import { useModalContext } from "src/components/common/Layout/context/ModalContext";
import {
  EnumMetrikActions,
  sendCustomYandexMetrik,
} from "src/config/analytics/sendCustomYandexMetrik";
import Cookie from "js-cookie";
import TopHeaderLinks from "src/components/common/Header/components/TopHeaderLinks";
import ButtonWithIcon from "src/components/common/Buttons/ButtonWithIcon";
import CatalogIcon from "src/assets/icons/CatalogIcon";
import { DEFAULT_CITY } from "src/utils/constant/constant";
import HeaderNavigation from "src/components/common/Header/components/HeaderNavigation";

const SearchModal = dynamic(
  import("src/components/common/SearchModal/SearchModal"),
  { ssr: false }
);
const AuthorizationModal = dynamic(
  import("src/components/AuthorizationModal/AuthorizationModal"),
  { ssr: false }
);
const ChangeCityModal = dynamic(
  import("src/components/common/ChangeCityModal/ChangeCityModal"),
  { ssr: false }
);
const CheckOrderStatusModal = dynamic(
  import("src/components/common/CheckOrderStatusModal/CheckOrderStatusModal"),
  { ssr: false }
);

const NewHeader: React.FC = () => {
  const cityInfo = useSelector(selectCity, shallowEqual);
  const router = useRouter();
  const dispatch = useDispatch();
  const { city } = useQuery(["city"]);
  const basketObject = useSelector(selectCount);
  const countProduct = useMemo(
    () => calcProductInBasket(basketObject),
    [basketObject]
  );
  const { modalType, openModal, closeModal } = useModalContext();

  useEffect(() => {
    dispatch(validationCity(city));
  }, [city]);

  const onClickBasket = () => {
    sendCustomYandexMetrik(EnumMetrikActions.goToBasketFromHeader);
    return router.push(createUrl([paths.BASKET]));
  };

  const onOpenChangeCityModal = () => {
    const noCityInformation = !Cookie.get("selectedCity") && !router.query.city;
    const controlModal = modalType === ModalType.CITY;

    if (noCityInformation) {
      return true;
    }
    return controlModal;
  };

  return (
    <React.Fragment>
      <AppBar
        sx={{
          boxShadow: "0px 4px 30px 0px rgba(51, 51, 51, 0.10)",
        }}
        position="sticky"
        elevation={1}
        color="inherit"
      >
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignItems: "center",
            width: "100%",
            paddingY: "8px",
            height: "36px",
            backgroundColor: "#F6F7F8",
          }}
        >
          <Wrapper
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <LocationButton
              openCityModal={() => openModal(ModalType.CITY)}
              cityName={cityInfo.name}
            />
            <TopHeaderLinks />
          </Wrapper>
        </Box>
        <Wrapper>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              color: colors.blue,
              height: { xs: "103px", lg: "88px" },
              width: "100%",
            }}
            disableGutters
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "space-between", lg: "center" },
                  width: "100%",
                  mr: 4,
                  mb: { xs: 1, lg: 0 },
                }}
              >
                <Box
                  onClick={() => router.push(baseUrlGeneration(router))}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ minWidth: "45px", minHeight: "38px" }}
                  >
                    <Image alt="Лого" width="45px" height="38px" src={Logo} />
                  </Box>
                  <Box>
                    <Typography
                      fontSize={{ xs: "13px", sm: "15px" }}
                      variant="h5"
                      component="div"
                      lineHeight="12px"
                      color={colors.blue}
                      sx={{
                        marginLeft: 1,
                        fontFamily: "HelveticaNeueCry",
                        fontWeight: 900,
                      }}
                    >
                      ЦЕНЫвАПТЕКАХ
                    </Typography>
                    <Typography
                      fontSize={10}
                      variant="h6"
                      lineHeight="10px"
                      component="div"
                      color="#7F8F9B"
                      sx={{
                        textTransform: "lowercase",
                        marginLeft: 1,
                        fontFamily: "HelveticaNeueCry",
                        fontWeight: 400,
                      }}
                    >
                      поиск выгодных предложений
                    </Typography>
                  </Box>
                </Box>
                <LocationButton
                  sx={{
                    display: { xs: "flex", lg: "none" },
                  }}
                  openCityModal={() => openModal(ModalType.CITY)}
                  cityName={cityInfo.name}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <ButtonWithIcon
                  onClick={() =>
                    router.push(
                      createUrl([
                        cityInfo.slug ? cityInfo.slug : DEFAULT_CITY,
                        "/catalog",
                      ])
                    )
                  }
                  sx={{
                    display: { xs: "none", lg: "flex" },
                    width: "125px",
                    height: "56px",
                    color: colors.black2,
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: 400,
                    borderRadius: "8px",
                    gap: "8px",
                    mr: 2,
                  }}
                >
                  <CatalogIcon />
                  Каталог
                </ButtonWithIcon>
                <SearchButton openModal={() => openModal(ModalType.SEARCH)} />
                <HeaderNavigation
                  countProduct={countProduct}
                  onClickBasket={onClickBasket}
                  onClickOrders={() => openModal(ModalType.CHECK_ORDER_STATUS)}
                />
              </Box>
            </Box>
          </Toolbar>
        </Wrapper>
      </AppBar>
      <SearchModal
        isOpen={modalType === ModalType.SEARCH}
        closeModal={closeModal}
      />
      <ChangeCityModal
        isOpen={onOpenChangeCityModal()}
        closeModal={closeModal}
      />
      <AuthorizationModal
        isOpen={modalType === ModalType.AUTHORIZATION}
        onClose={closeModal}
      />
      <CheckOrderStatusModal
        isOpen={modalType === ModalType.CHECK_ORDER_STATUS}
        onClose={closeModal}
      />
    </React.Fragment>
  );
};

export default NewHeader;

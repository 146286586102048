import * as React from "react";

function SvgArticleListIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={74}
      height={65}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.552 25.997v-1.183c0-5.924.002-11.847.006-17.77.006-4.42 2.65-7.043 7.085-7.044h42.081c4.402 0 7.067 2.67 7.068 7.082v46.504c0 6.789-4.613 11.408-11.39 11.41-16.968.005-33.936.005-50.903 0C4.88 64.996.222 60.315.214 53.67c-.01-6.803-.01-13.606 0-20.409 0-4.632 2.62-7.254 7.21-7.259h9.025l1.103-.005zm2.117 34.682H62.332c4.468 0 7.146-2.67 7.148-7.128.01-15.468.01-30.934 0-46.4 0-2.053-.776-2.828-2.815-2.828H24.688c-2.059 0-2.822.765-2.822 2.802 0 14.891-.022 29.782.018 44.673.008 3.081-.1 6.089-2.215 8.88zm-2.183-30.363H7.346c-2.024 0-2.768.735-2.77 2.733-.005 4.127-.005 8.255 0 12.382 0 3.045-.057 6.092.034 9.134.11 3.733 3.59 6.55 7.286 6.013 3.383-.487 5.64-3.137 5.65-6.713.021-7.544.008-15.087 0-22.63.006-.259-.027-.521-.06-.919z"
        fill="#11193E"
      />
      <path
        d="M55.413 20.87c1.012 0 2.025-.02 3.038 0 2.43.054 4.137 1.78 4.254 4.373a53.743 53.743 0 010 4.612c-.106 2.545-1.746 4.333-4.127 4.425a84.88 84.88 0 01-6.37 0c-2.45-.083-4.13-1.993-4.155-4.603-.015-1.47-.027-2.94 0-4.409.05-2.449 1.746-4.306 4.02-4.397 1.11-.044 2.219-.007 3.333-.007l.007.005zm3.022 8.861v-4.348H52.28v4.348h6.154zM45.591 42.924H31.41c-.336 0-.673.005-1.006-.02-1.127-.085-1.949-.807-1.989-1.739-.017-.466.17-.92.524-1.273.354-.353.85-.578 1.389-.63.167-.015.335-.02.502-.015H60.3c1.788 0 2.877 1.186 2.297 2.481-.383.859-1.174 1.197-2.22 1.194-3.017-.008-6.035 0-9.053 0-1.913.003-3.824.003-5.733.002zM45.44 51.504c-4.881 0-9.762.025-14.642-.03-.67-.007-1.534-.259-1.94-.666-.406-.408-.501-1.163-.414-1.73.108-.694.793-1.11 1.64-1.209.334-.032.67-.045 1.006-.036h29.085c1.614.02 2.61.744 2.578 1.87-.035 1.104-1.014 1.8-2.568 1.801-4.915.003-9.83.003-14.745 0zM45.508 11.041c2.865 0 5.731-.01 8.596.006 1.44.009 2.342.623 2.5 1.624.167 1.083-.82 1.977-2.314 2.024-1.276.04-2.555.013-3.831.013-4.35 0-8.7-.002-13.047-.007-.479 0-.984-.018-1.428-.145-.943-.268-1.442-.872-1.437-1.691.004-.819.512-1.413 1.457-1.68.413-.117.881-.135 1.325-.137 2.725-.012 5.452-.007 8.18-.007zM35.815 30.666c1.606 0 3.214-.013 4.821 0 1.514.014 2.468.715 2.494 1.797.03 1.108-.954 1.86-2.516 1.865-3.248.012-6.495.02-9.743 0-1.887-.013-2.984-1.361-2.202-2.657.445-.734 1.21-1 2.125-1.002 1.67-.005 3.344-.003 5.02-.003zM35.786 25.75c-1.675 0-3.35.01-5.024 0-1.409-.012-2.351-.766-2.348-1.847.003-1.08.923-1.812 2.364-1.817 3.318-.014 6.635-.014 9.95 0 1.445.004 2.38.722 2.403 1.792.024 1.108-.939 1.86-2.418 1.875-1.643.005-3.285-.003-4.927-.004z"
        fill="#11193E"
      />
    </svg>
  );
}

export default SvgArticleListIcon;

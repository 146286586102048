import React from "react";
import InputMask from "react-input-mask";
import { Control, Controller } from "react-hook-form";
import {
  FormControl,
  InputBaseComponentProps,
  InputBaseProps,
  TextField,
} from "@mui/material";
import { SxProps, Theme } from "@mui/system";

interface FiledMaskProps {
  control: Control<any>;
  inputPropsStyle?: InputBaseComponentProps;
  error?: string;
  label?: string;
  type?: string;
  autoFocus?: boolean;
  placeholder?: string;
  defaultValue?: string;
  notRequired?: boolean;
  sx?: SxProps<Theme>;
  formControlSx?: SxProps<Theme>;
}

const FieldMask: React.FC<FiledMaskProps> = ({
  control,
  inputPropsStyle,
  error,
  label,
  type,
  autoFocus,
  placeholder,
  defaultValue,
  notRequired,
  sx,
  formControlSx,
}) => {
  return (
    <Controller
      control={control}
      name="phone"
      defaultValue={defaultValue || ""}
      rules={
        notRequired
          ? {}
          : {
              required: "Обязательное поле",
              minLength: { value: 16, message: "Короткий номер" },
            }
      }
      render={({ field }) => {
        return (
          <InputMask
            mask="+7 999 999-99-99"
            // @ts-ignore
            maskChar=""
            alwaysShowMask={false}
            defaultValue={defaultValue || ""}
            {...field}
          >
            {(inputProps: InputBaseProps["inputProps"]) => (
              <FormControl sx={formControlSx}>
                <TextField
                  sx={sx}
                  InputLabelProps={{ shrink: true }}
                  error={!!error}
                  autoFocus={autoFocus}
                  margin="dense"
                  type={type}
                  size="small"
                  label={label}
                  variant="outlined"
                  fullWidth
                  inputProps={{ ...inputProps, ...inputPropsStyle }}
                  placeholder={placeholder}
                />
              </FormControl>
            )}
          </InputMask>
        );
      }}
    />
  );
};

export default FieldMask;

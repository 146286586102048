import React from "react";
import { Box, NoSsr } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import NewOrdersIcon from "src/assets/icons/NewOrdersIcon";
import Typography from "@mui/material/Typography";
import { colors } from "src/config/colors";
import Badge from "@mui/material/Badge";
import { CartIcon } from "src/assets/icons/index";

interface HeaderNavigationProps {
  onClickBasket?: () => void;
  onClickUser?: () => void;
  onClickOrders?: () => void;
  countProduct: number;
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  countProduct,
  onClickBasket,
  onClickUser,
  onClickOrders,
}) => {
  return (
    <Box
      sx={{
        ml: 4,
        display: { xs: "none", lg: "flex" },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        onClick={onClickOrders}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mr: 3,
          cursor: "pointer",
          color: colors.black2,
          ":hover": {
            color: "#3C56CB",
            svg: {
              fill: "#3C56CB",
            },
          },
        }}
      >
        <IconButton
          sx={{
            padding: "0",
            mb: 1,
            width: "24px",
            height: "24px",
          }}
          color="inherit"
          aria-label="menu"
        >
          <NewOrdersIcon />
        </IconButton>
        <Typography
          component="div"
          color="inherit"
          sx={{
            fontSize: "16px",
            lineHeight: "normal",
          }}
        >
          Заказы
        </Typography>
      </Box>
      <NoSsr
        fallback={
          <Box
            onClick={onClickBasket}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: colors.black2,
              ":hover": {
                color: "#3C56CB",
                svg: {
                  fill: "#3C56CB",
                },
              },
              /*mr: 3,*/
            }}
          >
            <IconButton
              sx={{
                padding: "0",
                mb: 1,
                width: "24px",
                height: "24px",
              }}
              color="inherit"
              aria-label="menu"
            >
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: colors.red,
                    color: colors.white,
                  },
                }}
                max={99}
              >
                <CartIcon />
              </Badge>
            </IconButton>
            <Typography
              component="div"
              sx={{
                color: "inherit",
                fontSize: "16px",
                lineHeight: "normal",
                cursor: "pointer",
              }}
            >
              Корзина
            </Typography>
          </Box>
        }
      >
        <Box
          suppressHydrationWarning
          onClick={onClickBasket}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            /*mr: 3,*/
            height: "52px",
            cursor: "pointer",
            color: colors.black2,
            ":hover": {
              color: "#3C56CB",
              svg: {
                fill: "#3C56CB",
              },
            },
          }}
        >
          <IconButton
            sx={{
              padding: "0",
              mb: 1,
              width: "24px",
              height: "24px",
            }}
            color="inherit"
            aria-label="menu"
          >
            <Badge
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: colors.red,
                  color: colors.white,
                },
              }}
              badgeContent={countProduct}
              max={99}
            >
              <CartIcon />
            </Badge>
          </IconButton>
          <Typography
            component="div"
            sx={{
              color: "inherit",
              fontSize: "16px",
              lineHeight: "normal",
            }}
          >
            Корзина
          </Typography>
        </Box>
      </NoSsr>
      {/*<Box
        suppressHydrationWarning
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mr: 3,
          cursor: "pointer",
        }}
      >
        <IconButton
          sx={{ padding: "0", mb: 1, width: "24px", height: "24px" }}
          color="inherit"
          aria-label="menu"
          onClick={onClickUser}
        >
          <UserIcon />
        </IconButton>
        <Typography
          component="div"
          sx={{
            color: colors.black2,
            fontSize: "16px",
            lineHeight: "normal",
          }}
        >
          Войти
        </Typography>
      </Box>*/}
    </Box>
  );
};

export default HeaderNavigation;

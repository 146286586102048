import React from "react";
import Link from "src/components/common/Links/Link";
import { colors } from "src/config/colors";
import paths from "src/router/paths";
import { Stack } from "@mui/material";

const TopHeaderLinks: React.FC = () => {
  return (
    <Stack spacing="40px" direction="row">
      <Link
        sx={{
          fontSize: "16px",
          color: colors.gray3,
          textDecoration: "none",
          ":hover": {
            color: "#3C56CB",
          },
        }}
        href={"/" + paths.ABOUT}
      >
        О нас
      </Link>
      <Link
        sx={{
          fontSize: "16px",
          color: colors.gray3,
          textDecoration: "none",
          ":hover": {
            color: "#3C56CB",
          },
        }}
        href={"/" + paths.BLOG}
      >
        Блог
      </Link>
      <Link
        sx={{
          fontSize: "16px",
          color: colors.gray3,
          textDecoration: "none",
          ":hover": {
            color: "#3C56CB",
          },
        }}
        href={"/" + paths.FAQ}
      >
        Частые вопросы
      </Link>
      <Link
        sx={{
          fontSize: "16px",
          color: colors.gray3,
          textDecoration: "none",
          ":hover": {
            color: "#3C56CB",
          },
        }}
        href={"/" + paths.CONTACTS}
      >
        Контакты
      </Link>
    </Stack>
  );
};

export default TopHeaderLinks;

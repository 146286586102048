import React from "react";
import ListItem from "@mui/material/ListItem";
import Link from "src/components/common/Links/Link";

interface LinkBurgerProps {
  href: string;
}

const LinkNext: React.FC<LinkBurgerProps> = ({ href, children }) => {
  return (
    <Link
      sx={{
        textDecoration: "none",
        lineHeight: "20px",
        color: "rgba(17, 25, 62, 1)",
      }}
      href={href}
    >
      {children}
    </Link>
  );
};

interface BurgerListItemProps {
  href?: string;
  title: string;
  fontSize?: number;
  icon?: any;
  onClick?: () => void;
}

const BurgerListItem: React.FC<BurgerListItemProps> = ({
  title,
  href,
  icon,
  fontSize = 15,
  onClick,
}) => (
  <>
    {href ? (
      <LinkNext href={href}>
        <ListItem
          button
          sx={{ fontSize: fontSize + "px", fontWeight: 500, paddingY: 1 }}
        >
          {icon && icon}
          {title}
        </ListItem>
      </LinkNext>
    ) : (
      <ListItem
        onClick={onClick}
        button
        sx={{ fontSize: fontSize + "px", fontWeight: 500, paddingY: 1 }}
      >
        {icon && icon}
        {title}
      </ListItem>
    )}
  </>
);

export default BurgerListItem;

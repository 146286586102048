import * as React from "react";

function SvgCardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.144 14.967c-.086 1.806-1.284 3.535-3.68 4.486-2.38.934-4.723.7-6.616-.92A324.277 324.277 0 012.535 9.297c-2.31-2.18-2.118-5.608.226-7.664 2.361-2.087 6.163-2.211 8.542-.155 3.473 3.006 6.858 6.105 10.244 9.19 1.128 1.028 1.632 2.32 1.597 4.299zm-8.368-7.524a271.382 271.382 0 01-3.247 2.929c-.747.67-1.111.685-1.823.046a266.26 266.26 0 01-3.924-3.52c-.834-.763-.886-1.558-.191-2.46.555-.717.555-1.263 0-1.636-.556-.359-1.18-.187-1.771.482-1.337 1.542-1.129 3.583.573 5.14 3.073 2.789 6.146 5.577 9.237 8.35.399.358.885.685 1.371.934 1.754.841 3.924.452 5.192-.903 1.232-1.324 1.389-3.38.07-4.658-1.685-1.65-3.578-3.1-5.487-4.704z"
        fill="#101839"
        fillOpacity={0.8}
      />
    </svg>
  );
}

export default SvgCardIcon;

import * as React from "react";

function SvgCrossClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={13}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.389.326a1.113 1.113 0 010 1.573L8.073 6.215l4.6 4.6A1.113 1.113 0 1111.1 12.39l-4.6-4.6-4.6 4.6a1.113 1.113 0 11-1.574-1.574l4.6-4.6L.611 1.899A1.113 1.113 0 012.184.326L6.5 4.64 10.815.326a1.113 1.113 0 011.574 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCrossClose;

import { BasketState } from "src/redux/slice/basketSlice";

export const setBasketToStorage = (state: BasketState) => {
  if (typeof window !== "undefined") {
    try {
      if (state) {
        localStorage.setItem("basket", JSON.stringify(state));
      }
    } catch (err) {
      console.log(err);
    }
  }
};

import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Stack from "@mui/material/Stack";
import { colors } from "src/config/colors";
import Typography from "@mui/material/Typography";
import { ButtonBase } from "@mui/material";
import Box from "@mui/material/Box";

interface CookieAlertProps {
  onClick: (e: React.MouseEvent) => void;
  clickNav: any;
}

const CookieAlert: React.FC<CookieAlertProps> = ({ onClick, clickNav }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <InfoIcon fontSize="small" sx={{ color: colors.yellow, marginX: "4px" }} />
    <Typography component="p" fontSize={12} color={colors.white}>
      На ценываптеках.рф есть cookies.&nbsp;
      <Typography
        component="a"
        fontSize={12}
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
          ":hover": { color: colors.yellow },
        }}
        onClick={clickNav}
      >
        Как это работает
      </Typography>
    </Typography>
    <Box>
      <ButtonBase
        sx={{
          width: 80,
          background: colors.yellow,
          padding: "4px",
          marginRight: 1,
          borderRadius: "4px",
          ":hover": {
            opacity: 0.7,
          },
        }}
        onClick={onClick}
      >
        <Typography fontSize={13} color={colors.blue} fontWeight={550}>
          Понятно
        </Typography>
      </ButtonBase>
    </Box>
  </Stack>
);

export default CookieAlert;

import * as React from "react";

function SvgPhoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.004 4.167a2.373 2.373 0 01.723-1.868c.285-.285.563-.58.844-.87.544-.565 1.201-.575 1.75-.01a88.83 88.83 0 011.552 1.633c.561.606.559 1.405.003 2.013-.262.287-.533.562-.806.84-.056.056-.064.099-.035.173.179.442.436.832.713 1.206.653.888 1.414 1.646 2.314 2.236.101.067.207.123.316.168.028.013.087-.01.113-.038.259-.272.508-.554.768-.824.254-.265.546-.46.909-.486.349-.026.652.112.897.371a82.952 82.952 0 011.607 1.736c.513.572.367 1.381-.05 1.862-.155.181-.325.344-.49.514-.1.107-.21.206-.299.326-.48.646-1.11.903-1.86.842-.766-.063-1.48-.323-2.168-.677-1.946-.997-3.552-2.44-4.85-4.292C2.217 7.97 1.62 6.839 1.223 5.589c-.144-.47-.245-.949-.219-1.422zm.744-.08c.008.688.172 1.372.467 2.072a13.026 13.026 0 002.17 3.473c1.208 1.402 2.62 2.497 4.27 3.217.521.226 1.056.39 1.624.371.346-.01.652-.124.902-.4.306-.335.612-.668.923-1 .285-.302.295-.625.012-.93-.476-.517-.954-1.034-1.432-1.548-.292-.313-.556-.313-.848 0l-.884.955c-.259.28-.464.302-.775.101-.205-.132-.421-.246-.622-.386-.794-.56-1.488-1.245-2.117-2.01-.441-.535-.823-1.115-1.082-1.777-.134-.34-.068-.61.168-.837.318-.308.621-.633.925-.962.193-.208.198-.437.036-.674a1.425 1.425 0 00-.132-.163 99.337 99.337 0 00-.862-.9c-.234-.24-.47-.474-.705-.708-.193-.19-.381-.211-.6-.064a1.12 1.12 0 00-.203.173c-.257.27-.507.555-.77.822a1.523 1.523 0 00-.465 1.176z"
        fill="#11193E"
        stroke="#11193E"
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgPhoneIcon;

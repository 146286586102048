import proxyInstance from "src/config/api/proxyInstance";
import { Tokens } from "./types";

interface PinData {
  phone: string;
  code: string;
}

export const confirmPinCode = (data: PinData) =>
  proxyInstance.post<Tokens>("users/auth/login_with_code", {
    code: data.code,
    phone: data.phone,
  });

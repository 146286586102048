import { useEffect } from "react";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_KEY } from "src/config";
import { useActions } from "../redux/useActions";

export const useAuth = () => {
  const { fetchUser } = useActions();

  useEffect(() => {
    const token = Cookies.get(ACCESS_TOKEN_KEY);

    token && fetchUser();
  }, []);
};

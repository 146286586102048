import * as React from "react";

function SvgProfileFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={42}
      height={42}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={42} height={42} rx={21} fill="#11193E" />
      <path
        d="M22.28 27.676c0 .172-.135.32-.306.32-.97 0-1.936.004-2.905.004-.352 0-.356-.004-.352-.374 0-2.448.004-4.896.004-7.344a.267.267 0 00-.267-.267h-1.203a.247.247 0 01-.247-.247c0-.733.008-1.47-.004-2.201-.004-.202.037-.276.25-.268.409.02.82.004 1.229.004a.238.238 0 00.238-.238v-.005c.004-.748-.008-1.493.013-2.242.02-.724.258-1.37.783-1.88a3.002 3.002 0 012.057-.868c1.054-.029 2.109-.041 3.163-.07.198-.004.255.066.25.26a90.746 90.746 0 00-.003 2.344c0 .177-.057.219-.218.214-.538-.008-1.075-.004-1.612-.004-.574.004-.87.3-.873.889-.004.506 0 1.016.004 1.522 0 .037.045.058.081.058h1.102a1.359 1.359 0 011.338 1.605 1.267 1.267 0 01-1.258 1.11c-.7 0-1.266.569-1.265 1.27.002 2.135.002 4.263.002 6.408z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgProfileFacebook;

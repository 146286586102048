import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { ModalType } from "src/utils/hooks/state/useModal";
import { DEFAULT_CITY } from "src/utils/constant/constant";
import DrawerMenuList from "src/components/common/DrawerMenu/components/DrawerMenuList";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export interface BurgerMenuProps {
  cityName?: string;
  citySlug?: string;
  openModal: (ModalType: ModalType) => void;
  sx?: SxProps<Theme>;
}

const DrawerMenu: React.FC<BurgerMenuProps> = ({
  cityName,
  citySlug = DEFAULT_CITY,
  openModal,
  sx,
}) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen((prev: boolean) => !prev);
    };

  return (
    <>
      <IconButton
        sx={sx}
        color="primary"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <DrawerMenuList
          toggleDrawer={toggleDrawer}
          openModal={openModal}
          cityName={cityName}
          citySlug={citySlug}
        />
      </Drawer>
    </>
  );
};

export default DrawerMenu;

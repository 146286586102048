import { createTheme } from "@mui/material/styles";
import { components } from "./components";
import { typography } from "./typography";
import { colors } from "src/config/colors";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    tb: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.yellow,
    },
    neutral: {
      main: "#F8F8F8",
    },
  },
  typography,
  components: components as any,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      tb: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

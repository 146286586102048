import * as React from "react";

function SvgFilterArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g data-name="Layer 2">
        <path d="M16 29a1 1 0 01-.71-.29l-9-9a1 1 0 011.42-1.42l8.29 8.3 8.29-8.3a1 1 0 011.42 1.42l-9 9A1 1 0 0116 29z" />
        <path d="M16 29a1 1 0 01-1-1V4a1 1 0 012 0v24a1 1 0 01-1 1z" />
      </g>
      <path fill="none" d="M0 0h32v32H0z" />
    </svg>
  );
}

export default SvgFilterArrow;

import * as React from "react";

function SvgSearchIcon2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.325 14.865a8.287 8.287 0 01-11.507-1.41c-2.655-3.329-2.373-8.123.655-11.07C5.535-.6 10.307-.804 13.555 1.891c3.37 2.798 4.06 7.793 1.271 11.486.065.038.152.073.213.134 1.514 1.51 3.028 3.024 4.541 4.539.425.425.53.885.304 1.332-.317.633-1.12.829-1.648.356-.517-.46-.994-.968-1.484-1.458l-3.266-3.268-.16-.147zM8.273 2.15c-3.379.013-6.129 2.773-6.116 6.14.013 3.38 2.772 6.131 6.138 6.118 3.374-.009 6.129-2.777 6.12-6.14-.017-3.376-2.78-6.127-6.142-6.118z"
        fill="#343E69"
      />
    </svg>
  );
}

export default SvgSearchIcon2;

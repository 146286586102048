import * as React from "react";

function SvgFilterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.997 0h8.174c.575 0 .817.246.83.8.022.98-.32 1.814-1.003 2.54-1.704 1.804-3.387 3.622-5.086 5.431a.482.482 0 00-.143.371c.004 1.105.004 2.21.004 3.315 0 .858-.402 1.488-1.193 1.875a385.875 385.875 0 01-3.248 1.56c-.601.287-1.094-.009-1.094-.655 0-2.03-.005-4.056.004-6.086a.546.546 0 00-.165-.405C4.348 6.9 2.617 5.053.897 3.206.2 2.464-.056 1.584.01.604.03.28.312.025.65.004c.073-.004.143 0 .216 0C3.573 0 6.286 0 8.996 0zm-1.38 14.253c.078-.033.134-.054.182-.079.71-.342 1.414-.684 2.128-1.021.32-.15.462-.388.462-.73-.008-1.221 0-2.447-.004-3.668 0-.23.07-.417.23-.584.64-.68 1.275-1.36 1.91-2.043 1.194-1.271 2.388-2.547 3.577-3.818.26-.276.433-.592.502-.96H1.428c.087.426.299.76.584 1.068 1.79 1.905 3.572 3.814 5.363 5.72a.84.84 0 01.242.62c-.008.78-.004 1.556-.004 2.335.004 1.038.004 2.08.004 3.16z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgFilterIcon;

import React from "react";
import Box from "@mui/material/Box";
import Image from "next/image";
import Logo from "public/assets/icons/headerLogo2.png";
import Typography from "@mui/material/Typography";
import { colors } from "src/config/colors";
import { Divider } from "@mui/material";
import List from "@mui/material/List";
import BurgerListItem from "src/components/common/DrawerMenu/components/BurgerListItem";
import paths from "src/router/paths";
import LocationButton from "src/components/common/Header/components/LocationButton";
import { ModalType } from "src/utils/hooks/state/useModal";
import { useRouter } from "next/router";
import { BurgerMenuProps } from "src/components/common/DrawerMenu/DrawerMenu";
import { createUrl } from "src/utils/helpers/url/createUrl";
import { DEFAULT_CITY } from "src/utils/constant/constant";
import { baseUrlGeneration } from "src/utils/helpers/url/baseUrlGeneration";
import HomeIcon from "src/assets/icons/HomeIcon";
import HealthLiteracyIcon from "src/assets/icons/HealthLiteracyIcon";
import BlogIcon from "src/assets/icons/BlogIcon";
import FaqIcon from "src/assets/icons/FaqIcon";
import InventoryIcon from "@mui/icons-material/Inventory";
import ContactsIcon from "@mui/icons-material/Contacts";
import InfoIcon from "@mui/icons-material/Info";

const _navItemsList = [
  {
    href: (citySlug?: string) =>
      createUrl([citySlug || DEFAULT_CITY, "catalog"]),
    title: "Каталог товаров",
    icon: <HealthLiteracyIcon style={{ marginRight: 8 }} />,
  },
  {
    href: () => createUrl([paths.BLOG]),
    title: "Блог",
    icon: <BlogIcon style={{ marginRight: 8 }} />,
  },
  {
    href: () => createUrl([paths.FAQ]),
    title: "Частые вопросы",
    icon: <FaqIcon style={{ marginRight: 8 }} />,
  },
  {
    href: () => createUrl([paths.CONTACTS]),
    title: "Контакты",
    icon: <ContactsIcon style={{ marginRight: 8 }} />,
  },
  {
    href: () => createUrl([paths.ABOUT]),
    title: "О нас",
    icon: <InfoIcon style={{ marginRight: 8 }} />,
  },
];

interface DrawerMenuListProps extends BurgerMenuProps {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const DrawerMenuList: React.FC<DrawerMenuListProps> = ({
  openModal,
  cityName,
  citySlug,
  toggleDrawer,
}) => {
  const router = useRouter();
  /*const { authStage } = useSelector(authData, shallowEqual);*/
  /*const isAuth = authStage === AuthStage.auth;*/

  /*const showAuthHandler = () => {
    if (isAuth) {
      router.push("/profile/user");
    } else {
      openModal(ModalType.AUTHORIZATION);
    }
    toggleDrawer(false);
  };*/

  /* const orderStatusHandler = () => {
    if (isAuth) {
      return router.push(paths.PROFILE_ORDERS);
    } else {
      return openModal(ModalType.AUTHORIZATION);
    }
  };*/

  return (
    <React.Fragment>
      <Box
        width={273}
        onKeyDown={() => toggleDrawer(false)}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Box display="flex" alignItems="center" marginY={2} marginLeft={2}>
          <Box display="flex" alignItems="center">
            <Image alt="Лого" width="45px" height="38px" src={Logo} />
          </Box>
          <div>
            <Typography
              fontSize={13}
              variant="h5"
              component="h5"
              color={colors.blue}
              lineHeight="12px"
            >
              ЦЕНЫвАПТЕКАХ
            </Typography>
            <Typography
              fontSize={9}
              variant="h6"
              lineHeight="12px"
              component="h5"
              color={colors.darkGray}
            >
              поиск выгодных предложений
            </Typography>
          </div>
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <List disablePadding onClick={toggleDrawer(false)}>
          <BurgerListItem
            title="Главная"
            href={baseUrlGeneration(router)}
            icon={<HomeIcon style={{ marginRight: 8 }} />}
            fontSize={18}
          />
          <BurgerListItem
            onClick={() => openModal(ModalType.CHECK_ORDER_STATUS)}
            title="Узнать статус заказа"
            icon={<InventoryIcon style={{ marginRight: 8 }} />}
            fontSize={15}
          />
          {_navItemsList.map((value) => (
            <BurgerListItem
              title={value.title}
              href={value.href(citySlug)}
              key={value.href(citySlug)}
              icon={value.icon}
            />
          ))}
          {/*<ListItem
            button
            onClick={orderStatusHandler}
            sx={{
              fontSize: 15,
              fontWeight: 500,
              paddingY: 1,
              cursor: "pointer",
            }}
          >
            <MyOrderIcon style={{ marginRight: 8 }} />
            Мои заказы
          </ListItem>*/}
        </List>
        {/*<DrawerMenuAccordion citySlug={citySlug} />*/}
        <Divider sx={{ marginY: 1 }} />
        {/* убраны до их появления */}
        {/*<SocialIcons
          spacing={4}
          sx={{ display: "none", fill: colors.darkBlue, mt: 2, pl: 2 }}
        />*/}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="auto"
          borderTop="1px solid rgba(0, 0, 0, 0.12)"
          paddingY={1}
          paddingX={1.5}
        >
          <LocationButton
            openCityModal={() => openModal(ModalType.CITY)}
            cityName={cityName}
            isBolt
          />
          {/*<Button
            onClick={showAuthHandler}
            startIcon={<AuthorizationIcon />}
            disableRipple
            sx={{
              textTransform: "none",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            {isAuth ? "Профиль" : "Войти"}
          </Button>*/}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default DrawerMenuList;

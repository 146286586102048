import * as React from "react";

function SvgLocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#locationIcon_svg__clip0_1022_1292)" fill="#3C56CB">
        <path d="M7.512 19.74a.587.587 0 00.976 0c1.386-2.08 3.429-4.65 4.852-7.262 1.138-2.09 1.69-3.87 1.69-5.447A7.04 7.04 0 008 0 7.04 7.04 0 00.969 7.031c0 1.576.553 3.358 1.69 5.447 1.423 2.61 3.47 5.186 4.853 7.262zM8 1.171c3.23 0 5.86 2.628 5.86 5.86 0 1.374-.507 2.973-1.55 4.885-1.226 2.252-2.977 4.536-4.31 6.457-1.333-1.92-3.084-4.205-4.31-6.457-1.043-1.912-1.55-3.51-1.55-4.886C2.14 3.8 4.77 1.17 8 1.17v.001z" />
        <path d="M8 10.547a3.52 3.52 0 003.516-3.516A3.52 3.52 0 008 3.516 3.52 3.52 0 004.484 7.03 3.52 3.52 0 008 10.547zm0-5.86a2.346 2.346 0 012.344 2.344A2.346 2.346 0 018 9.375 2.346 2.346 0 015.656 7.03 2.346 2.346 0 018 4.688v-.001z" />
      </g>
      <defs>
        <clipPath id="locationIcon_svg__clip0_1022_1292">
          <path fill="#fff" d="M0 0h16v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLocationIcon;

import React from "react";
import { colors } from "src/config/colors";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ButtonBase } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface OrderingAlertProps {
  onClick: (e: React.MouseEvent) => void;
}

const OrderingAlert: React.FC<OrderingAlertProps> = ({ onClick }) => {
  return (
    <Box
      width="100%"
      display="flex"
    >
      <Box display="flex" alignItems="center">
        <CheckIcon
          fontSize="small"
          sx={{ color: colors.yellow, marginX: "4px" }}
        />
        <Typography
          marginRight="10px"
          component="p"
          fontSize={12}
          color={colors.white}
        >
          Товар добавлен в корзину
        </Typography>
      </Box>
      <ButtonBase
        sx={{
          marginLeft: "auto",
          width: 80,
          background: colors.yellow,
          padding: "4px",
          marginRight: 1,
          borderRadius: "4px",
          ":hover": {
            opacity: 0.7,
          },
        }}
        onClick={onClick}
      >
        <Typography fontSize={13} color={colors.blue} fontWeight={550}>
          ПЕРЕЙТИ
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default OrderingAlert;

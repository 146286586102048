import {
  authActions,
  fetchUser,
  loginWithCode,
  logoutUser,
} from "./slice/authSlice";
import { basketSliceActions } from "src/redux/slice/basketSlice";
import { citySliceActions } from "src/redux/slice/citySlice";

export default {
  ...authActions,
  ...basketSliceActions,
  ...citySliceActions,
  fetchUser,
  loginWithCode,
  logoutUser,
};

import * as React from "react";

function SvgYellowRightArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12} r={12} fill="#F7E64D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.22 8.22a.75.75 0 000 1.06l2.47 2.47-2.47 2.47a.75.75 0 101.06 1.06l3.53-3.53-3.53-3.53a.75.75 0 00-1.06 0z"
        fill="#11193E"
      />
    </svg>
  );
}

export default SvgYellowRightArrowIcon;

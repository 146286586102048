import * as React from "react";

function SvgPlaceMarkerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={17} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.165 22c-.063-.06-.117-.125-.184-.177-2.265-1.825-4.22-3.91-5.707-6.372C1.078 13.479.297 11.373.058 9.09c-.202-1.95.1-3.802 1.24-5.464C2.774 1.487 4.845.264 7.514.03c4.17-.357 7.932 2.364 8.75 6.3.22 1.06.184 2.123.044 3.186-.386 2.924-1.662 5.486-3.46 7.837-1.28 1.68-2.777 3.165-4.444 4.495-.054.043-.099.1-.148.151h-.09z" />
    </svg>
  );
}

export default SvgPlaceMarkerIcon;

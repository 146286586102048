import * as React from "react";

function SvgNotebookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={34}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.09 29.591C1.066 29.493.015 28.646 0 27.147c-.017-1.534 1.006-2.385 3.06-2.519V11.09c-.985.072-1.889-.123-2.536-.956a2.357 2.357 0 01-.52-1.613c.04-.771.382-1.398.999-1.873.616-.475 1.32-.517 2.09-.487V4.213C3.092 1.707 4.794.001 7.302 0h22.446C32.312 0 34 1.688 34 4.25v27.303c0 2.474-1.72 4.183-4.203 4.184H7.315c-2.51 0-4.217-1.706-4.22-4.206-.007-.638-.006-1.277-.006-1.94zm1.857.023v2.006c.007 1.366.908 2.266 2.28 2.267h22.637c1.368 0 2.278-.905 2.28-2.268V4.123c0-1.372-.903-2.276-2.271-2.276H7.195c-1.342 0-2.245.913-2.25 2.257V6.84c0 .854-.31 1.168-1.154 1.17H2.63c-.489 0-.774.231-.774.61 0 .38.283.618.767.618h2.782c.525 0 .705-.166.779-.695.066-.475.374-.791.817-.84.565-.064 1.017.347 1.031.937.029 1.256-1.062 2.391-2.356 2.44-.238.01-.478 0-.733 0V25.385c0 .77-.334 1.099-1.105 1.102-.438 0-.876-.006-1.316 0-.397.008-.661.253-.668.6-.007.362.263.624.68.627.992.007 1.984.007 2.975 0 .4 0 .611-.21.668-.603.093-.644.487-.995 1.044-.925.556.07.864.526.803 1.157-.102 1.028-.869 1.92-1.884 2.142-.37.091-.761.09-1.196.13h.003z"
        fill="#11193E"
      />
      <path
        d="M11.743 17.907v12.708c0 .444-.1.822-.529 1.026-.642.309-1.316-.147-1.325-.892-.01-1.157-.004-2.31-.004-3.466V5.256a3.844 3.844 0 01.009-.423.907.907 0 01.91-.826.909.909 0 01.929.808c.011.129.015.257.01.386.001 4.235.002 8.47 0 12.706z"
        fill="#1CCDF7"
      />
      <path
        d="M21.482 10.166h-5.176c-.13.006-.259.002-.387-.013a.915.915 0 01-.774-.916.897.897 0 01.825-.908c.27-.024.542-.01.81-.01H26.904c.53.03.902.401.91.908a.895.895 0 01-.563.868.904.904 0 01-.36.065c-1.056.014-2.111.005-3.167.006h-2.24zM21.48 12.939h5.293c.596 0 .977.308 1.034.82.064.57-.356 1.02-.982 1.025-.991.008-1.983 0-2.974 0h-7.688c-.684 0-1.128-.501-1.006-1.108.097-.468.464-.737 1.03-.738h5.294zM21.477 17.56h5.138c.129-.004.258-.001.387.01a.913.913 0 01.81.882.913.913 0 01-.791.942 2.778 2.778 0 01-.387.012h-10.35c-.722 0-1.134-.332-1.14-.915-.005-.582.408-.932 1.118-.933 1.739 0 3.477 0 5.215.002zM21.478 24.03h-5.293c-.537 0-.877-.23-1.006-.667-.18-.617.258-1.171.948-1.177 1.094-.01 2.189 0 3.283 0h7.378c.796 0 1.262.7.914 1.355-.197.37-.528.494-.933.494-1.761-.007-3.525-.004-5.29-.004zM21.477 28.652h-5.291c-.538 0-.879-.231-1.007-.668-.18-.617.259-1.17.948-1.177 1.094-.01 2.19 0 3.284 0h7.378c.794 0 1.261.701.913 1.355-.197.37-.528.495-.933.493a1296.23 1296.23 0 00-5.292-.003z"
        fill="#11193E"
      />
    </svg>
  );
}

export default SvgNotebookIcon;

import * as React from "react";

function SvgFeedbackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.897 17.794v-4.205a.77.77 0 011.539 0v5.642a.767.767 0 01-1.196.64l-2.818-1.88a.258.258 0 00-.143-.043H2.821A2.82 2.82 0 010 15.128V6.922a2.82 2.82 0 012.82-2.82h3.077a.77.77 0 010 1.538H2.821c-.708 0-1.283.575-1.283 1.282v8.206c0 .707.575 1.282 1.283 1.282h10.458c.355 0 .7.105.996.302l1.622 1.082z"
        fill="#343E69"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.846 0a6.158 6.158 0 00-6.154 6.154c0 1.114.297 2.16.819 3.058a.26.26 0 01.036.18c-.092.525-.245 1.418-.245 1.418a.77.77 0 00.884.89s.904-.15 1.432-.232a.253.253 0 01.176.03l.008.005A6.158 6.158 0 0020 6.154 6.158 6.158 0 0013.846 0zm-3.845 10.008l.373-.059c.414-.068.838.01 1.191.218a4.617 4.617 0 006.896-4.013 4.617 4.617 0 00-4.615-4.615 4.617 4.617 0 00-4.615 4.615c0 .832.22 1.614.607 2.28.218.366.296.798.225 1.219l-.062.355z"
        fill="#343E69"
      />
      <path
        d="M11.71 6.925a.684.684 0 100-1.368.684.684 0 000 1.368zM13.932 6.925a.684.684 0 100-1.368.684.684 0 000 1.368zM16.153 6.925a.684.684 0 100-1.368.684.684 0 000 1.368z"
        fill="#343E69"
      />
    </svg>
  );
}

export default SvgFeedbackIcon;

import { useQuery } from "src/utils/hooks/common/useQuery";
import { useEffect } from "react";
import Cookie from "js-cookie";
import { setRxoVisit } from "src/models/rxoVisit/rxoVisit";

export const useRXO = () => {
  const query = useQuery(["rxo_uuid"]);
  const daysToExpire = 2;

  useEffect(() => {
    if (query.rxo_uuid) {
      Cookie.set("rxo_uuid", query.rxo_uuid, {
        expires: daysToExpire,
      });
      setRxoVisit(query.rxo_uuid).catch((e) => console.log(e));
    }
  }, [query.rxo_uuid]);
};

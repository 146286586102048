import { StyledSx } from "src/types/StyledSx";

const BottomNavigationStyle: StyledSx = {
  bottomNav: {
    width: "100%",
    height: "60px",
    position: "fixed",
    top: "auto",
    bottom: 0,
    left: 0,
    zIndex: 1199,
    boxShadow: "0px -4px 4px rgba(15, 15, 15, 0.1)",
    alignItems: "center",
    display: { md: "flex", lg: "none" },
    ".MuiBottomNavigationAction-root": {
      minWidth: "60px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    ".MuiButtonBase-root": {
      display: "flex",
      justifyContent: "space-between",
    },
    ".MuiBottomNavigationAction-label": {
      fontSize: "11px",
    },
  },
  bottomNavButton: {
    padding: 0,
    width: "100%",
    minHeight: "44px",
  },
};

export default BottomNavigationStyle;

import * as React from "react";

function SvgCurrencyPaperIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={41}
      height={42}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.867 21.559v1.305c.752 0 1.493-.012 2.234 0 .799.017 1.256.789.876 1.459-.2.353-.521.49-.917.49h-2.208v1.468c0 .706-.386 1.142-.99 1.134-.59-.007-.96-.434-.964-1.12 0-.448 0-.898-.004-1.343a1.194 1.194 0 00-.026-.14H10.15c-.189 0-.38.007-.57 0-.555-.037-.94-.443-.939-.98a.947.947 0 01.949-.965c.623-.018 1.247-.005 1.871-.006h.415v-1.306h-2.18c-.378 0-.692-.121-.896-.449-.415-.653.014-1.464.798-1.501.38-.018.76-.005 1.142-.005h1.162v-.44-4.924c0-.79.356-1.158 1.136-1.15 1.287.016 2.586-.053 3.857.097 1.99.234 3.477 2.093 3.474 4.146-.003 2.052-1.504 3.834-3.49 4.149-.309.046-.62.07-.93.073-.683.015-1.36.008-2.082.008zm.006-6.498v4.541c.778 0 1.534.011 2.289 0a2.266 2.266 0 002.245-2.138c.064-1.205-.767-2.272-1.968-2.386-.841-.081-1.7-.017-2.566-.017z"
        fill="#1CCDF7"
      />
      <path
        d="M20.847 1.996H4.46c-1.584 0-2.505.918-2.505 2.498v32.2c0 1.473.943 2.426 2.412 2.427 4.668.004 9.336.004 14.004 0 .434 0 .81.09 1.04.494a.973.973 0 01-.717 1.453 2.713 2.713 0 01-.366.01H4.487C1.789 41.078 0 39.283 0 36.591V4.514C0 1.843 1.795.044 4.464.044h17.3c3.671.005 6.67 2.418 7.424 5.974.095.492.143.992.144 1.494.01.514-.396.979-.898.979-1.354.008-2.721.072-4.062-.078-1.972-.22-3.464-1.993-3.515-3.982-.027-.795-.01-1.593-.01-2.435zm6.382 4.547c-.298-2.19-2.786-4.619-4.43-4.358 0 .717-.012 1.435 0 2.154.025 1.142.873 2.102 2.01 2.191.797.063 1.6.012 2.42.012z"
        fill="#11193E"
      />
      <path
        d="M33.681 38.811c2.046.961 4.666-.342 5.221-2.596.403-1.622-.054-3.002-1.355-4.071-.349-.288-.513-.63-.42-1.07.082-.378.344-.606.71-.709.368-.102.658.042.942.254 2.51 1.876 2.966 5.643.99 8.175-1.802 2.307-4.932 2.955-7.488 1.53-.26-.145-.444-.137-.703-.012a7.49 7.49 0 01-10.72-5.83c-.497-3.95 2.342-7.718 6.246-8.295 4.108-.607 7.899 2.092 8.566 6.11.384 2.318-.198 4.377-1.705 6.176l-.284.338zm-5.358-10.76a5.528 5.528 0 00-5.525 5.548 5.535 5.535 0 007.665 5.102 5.536 5.536 0 002.985-7.242 5.533 5.533 0 00-5.125-3.407z"
        fill="#1CCDF7"
      />
      <path
        d="M29.31 17.303v5.413c0 .596-.287.98-.784 1.081-.627.127-1.16-.326-1.166-1.02-.01-1.1 0-2.198 0-3.298v-7.61c0-.807.666-1.29 1.36-.997.446.188.594.558.593 1.017-.004 1.805-.005 3.61-.003 5.414z"
        fill="#11193E"
      />
    </svg>
  );
}

export default SvgCurrencyPaperIcon;

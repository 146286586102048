import * as React from "react";

function SvgComputerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={37}
      height={35}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.271 29.76c.085.681.112 1.315.254 1.92.17.69.97 1.22 1.693 1.222.154-.005.31 0 .463.014a.98.98 0 01.846.87.995.995 0 01-.61 1.052 1.45 1.45 0 01-.494.085c-4.884.004-9.768.004-14.653 0-.696 0-1.138-.424-1.125-1.043.013-.545.403-.936.963-.98.317-.024.657-.008.946-.117.782-.296 1.211-.881 1.242-1.737.015-.406.046-.811.074-1.291h-.514c-3.153 0-6.307.01-9.46-.011-.452 0-.944-.072-1.347-.262-.938-.443-1.362-1.25-1.361-2.285V8.867 3.672c0-1.783.955-2.747 2.73-2.748h30.404c1.687 0 2.68.992 2.68 2.676v23.482c0 1.683-.993 2.677-2.678 2.678H23.27zM2.213 22.015H33.98c.007-.163.02-.301.02-.438V3.633c0-.592-.091-.677-.689-.677H2.957c-.67 0-.744.077-.744.754v18.306zm31.758 2.132H2.214v2.855c0 .642.085.728.729.728h30.266c.112 0 .254.04.33-.014.163-.116.408-.278.413-.429.035-1.037.018-2.076.018-3.14h.001zm-19.064 8.579h6.36v-1.322h-6.18c-.061.005-.121.014-.18.026v1.296z"
        fill="#11193E"
      />
      <path
        d="M22.433 14.257c1.135 1.333.36 2.974-.874 3.289-.802.204-1.484-.006-2.016-.625-.514-.592-.608-1.293-.294-2.01.116-.265.095-.41-.11-.611-.754-.737-1.486-1.496-2.238-2.258-.741.443-1.462.459-2.12.05-.826.8-1.63 1.566-2.411 2.355-.072.073-.042.304.01.433.316.773.162 1.608-.423 2.18a1.946 1.946 0 01-2.188.381 1.97 1.97 0 01-1.136-1.914c.085-1.329 1.48-2.2 2.7-1.65.302.136.445.062.64-.138.708-.724 1.43-1.438 2.145-2.153a.592.592 0 00.06-.095c-.272-.508-.409-1.048-.228-1.638a1.967 1.967 0 012.53-1.27 1.916 1.916 0 011.136 2.578c-.125.281-.1.437.117.645.67.642 1.323 1.303 1.97 1.969.178.183.31.205.573.133.485-.136 1-.153 1.64-.236l1.974-2.981c.271-.41.53-.83.819-1.23.155-.211.169-.349-.006-.575-.48-.621-.482-1.507-.054-2.18a1.968 1.968 0 012.008-.881c.8.152 1.382.715 1.542 1.488.3 1.447-.91 2.658-2.38 2.393-.131-.023-.353.022-.415.115-.998 1.464-1.976 2.941-2.971 4.436z"
        fill="#1CCDF7"
      />
      <path
        d="M18.109 24.89a1.022 1.022 0 01.992 1.04 1.022 1.022 0 01-1.05.982 1.025 1.025 0 01-.97-1.016 1.023 1.023 0 011.028-1.006z"
        fill="#11193E"
      />
    </svg>
  );
}

export default SvgComputerIcon;

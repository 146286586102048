import React from "react";
import { colors } from "src/config/colors";
import { ThemeOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    grayTitle1: React.CSSProperties;
    grayTitle2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    grayTitle1?: React.CSSProperties;
    grayTitle2?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    grayTitle1: true;
    grayTitle2: true;
  }
}

export const typography: ThemeOptions["typography"] = {
  h1: {
    fontSize: "30px",
    fontWeight: 550,
    lineHeight: "36px",
  },
  h2: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "24px",
    color: colors.blue,
  },
  h3: {
    fontSize: "21px",
    fontWeight: 400,
    lineHeight: "25px",
    color: colors.blue,
  },
  h4: {
    fontSize: "17px",
    fontWeight: 400,
    color: colors.darkBlue,
  },
  h5: {
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  h6: {
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "20px",
    color: colors.blue,
  },
  subtitle1: {
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  subtitle2: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    color: colors.darkGray,
  },
  body1: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "25px",
    color: colors.shadowBlue,
  },
  body2: {
    fontSize: "17px",
    lineHeight: "25px",
    fontWeight: 400,
    color: colors.shadowBlue,
  },
  caption: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
  },
  button: {
    fontWeight: 550,
    fontSize: "12px",
    lineHeight: "12px",
    color: colors.blue,
  },
  grayTitle1: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    color: colors.shadowBlue,
  },
  grayTitle2: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: colors.shadowBlue,
  },
};

import * as React from "react";

function SvgWatchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6c0 3.298-2.677 5.988-5.988 6C2.714 12.012 0 9.31 0 6.012 0 2.69 2.702-.012 6.012 0 9.31.012 11.988 2.69 12 6zm-6.012 4.905c2.677.012 4.892-2.167 4.917-4.856.024-2.714-2.167-4.941-4.844-4.954-2.726-.024-4.953 2.155-4.966 4.856-.024 2.739 2.154 4.93 4.893 4.954z"
        fill="#11193E"
      />
      <path
        d="M5.452 4.357v-1.63c0-.256.183-.488.426-.536a.53.53 0 01.609.328.71.71 0 01.036.28v2.86c0 .061.037.146.085.195.45.462.913.913 1.363 1.363.305.304.232.742-.134.913-.219.097-.438.048-.645-.146-.23-.232-.462-.463-.693-.694-.28-.292-.572-.572-.864-.852a.644.644 0 01-.207-.499c.036-.535.024-1.059.024-1.582z"
        fill="#11193E"
      />
    </svg>
  );
}

export default SvgWatchIcon;

import React from "react";
import { Button, ButtonProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface SubmitButtonProps {
  title: string;
  width?: number;
  maxWidth?: number;
  height?: number;
  className?: string;
  color?: ButtonProps["color"];
  form?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
  typographySx?: SxProps<Theme>;
  minHeight?: number;
  minWidth?: number;
  href?: string;
  type?: "button" | "submit" | "reset";
  icon?: React.ReactElement;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  title,
  width,
  minWidth,
  color = "secondary",
  maxWidth,
  height,
  className,
  form,
  disabled,
  onClick,
  sx,
  typographySx,
  minHeight,
  href,
  type,
  icon,
}) => {
  return (
    <Button
      onClick={onClick}
      href={href}
      {...(onClick || href ? {} : { form: form, type: "submit" })}
      variant="contained"
      color={color}
      className={className}
      fullWidth
      disableElevation
      disabled={disabled}
      type={type}
      sx={{
        width: width ? width : null,
        height: height ? height : null,
        maxWidth: maxWidth ? maxWidth : null,
        minHeight: minHeight,
        minWidth: minWidth ? minWidth : null,
        ...sx,
      }}
    >
      {icon}
      <Typography
        variant="button"
        component="span"
        color="inherit"
        sx={{
          textTransform: "none",
          fontSize: { xs: "14px", md: "16px", fontWeight: 400 },
          ...typographySx,
        }}
      >
        {title}
      </Typography>
    </Button>
  );
};

export default SubmitButton;

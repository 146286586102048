import { colors } from "src/config/colors";
import { StyledSx } from "src/types/StyledSx";

const FooterStyled: StyledSx = {
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 54,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 20,
  },
  form: {
    position: "relative",
    marginBottom: "15px",
  },
  submitButton: {
    height: 55.6,
    fontSize: 14,
    fontWeight: 550,
    width: 53,
    position: "absolute",
    right: "0",
  },
  textField: {
    mb: 2,
    width: "100%",
    ".MuiInputBase-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-input": {
      background: colors.white,
      fontSize: "14px",
      padding: "16px",
    },
  },
  footerIcons: {
    marginRight: "40px",
  },
  yMap: {},
};

export default FooterStyled;

import React from "react";
import InputWithButton from "src/components/common/InputWithButton/InputWithButton";

interface SearchButtonProps {
  openModal: () => void;
}

const SearchButton: React.FC<SearchButtonProps> = ({ openModal }) => {
  const openSearch = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target!.blur();
    openModal();
  };

  return (
    <InputWithButton
      onFocus={openSearch}
      paperSx={{
        borderRadius: "8px 0px 0px 8px",
        border: "1px solid var(--yellow, #F7E64D)",
        boxShadow: "0",
      }}
    />
  );
};

export default SearchButton;

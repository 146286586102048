import * as React from "react";

function SvgHeaderSearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 17c-3.456 0-6-2.544-6-6s2.544-6 6-6 6 2.544 6 6-2.544 6-6 6zm0 2c4.439 0 8-3.561 8-8s-3.561-8-8-8-8 3.561-8 8 3.561 8 8 8zm9.702.298l-4-4A.927.927 0 0016 15c-.544 0-1 .456-1 1 0 .28.105.526.298.702l4 4A.927.927 0 0020 21c.544 0 1-.456 1-1a.927.927 0 00-.298-.702z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgHeaderSearchIcon;

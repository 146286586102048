import React from "react";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import LocationIcon from "src/assets/icons/LocationIcon";
import { SxProps, Theme } from "@mui/system";

interface LocationButtonProps {
  openCityModal: () => void;
  cityName?: string;
  isBolt?: boolean;
  sx?: SxProps<Theme>;
}

const LocationButton: React.FC<LocationButtonProps> = ({
  openCityModal,
  cityName,
  isBolt,
  sx,
}) => {
  return (
    <Button
      sx={{
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mr: { xs: 0, md: 2 },
        ":hover": {
          backgroundColor: "transparent",
        },
        ...sx,
      }}
      disableRipple
      color="inherit"
      onClick={openCityModal}
    >
      <LocationIcon />
      <Typography
        variant="h3"
        component="span"
        align="inherit"
        sx={{
          fontSize: { xs: "12px", sm: "16px" },
          fontWeight: "400",
          textDecoration: "none",
          cursor: "pointer",
          textTransform: "none",
          width: "fit-content",
          ml: 1,
          ":hover": {
            color: "#3C56CB",
          },
        }}
      >
        {cityName || "Выберите город"}
      </Typography>
    </Button>
  );
};

export default LocationButton;

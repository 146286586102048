import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { colors } from "src/config/colors";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";

interface FooterNavItemProps {
  title: string;
  itemLink: any;
  isDark?: boolean;
  disableDivider?: boolean;
  isLargeScreenDown?: boolean;
  disableExpandIcon?: boolean;
}

const FooterNavItem: React.FC<FooterNavItemProps> = ({
  title,
  itemLink,
  isDark,
  disableDivider,
  isLargeScreenDown,
  disableExpandIcon,
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <>
      {!disableDivider && (
        <Divider sx={{ bgcolor: "rgba(255, 255, 255, 0.2)" }} />
      )}
      <Accordion
        sx={{
          bgcolor: isDark ? colors.darkBlue : colors.white,
          boxShadow: "none",
          width: "100%",
          ":before": {
            display: { tb: "none" },
          },
        }}
        disableGutters
        expanded={isLargeScreenDown || isExpanded}
        onChange={handleChange()}
      >
        <AccordionSummary
          sx={{
            padding: "0",
          }}
          expandIcon={
            <ExpandMoreIcon
              sx={{
                display: disableExpandIcon ? { lg: "none" } : "block",
                color: isDark ? colors.white : colors.darkBlue,
              }}
            />
          }
        >
          <Typography
            component="span"
            fontSize={16}
            lineHeight="22px"
            sx={{
              color: colors.white,
              paddingY: "5px",
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0 0 5px 0",
          }}
        >
          {itemLink}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FooterNavItem;

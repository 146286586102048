import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "src/redux/store";
import {
  getCityCookie,
  setCityCookie,
} from "src/utils/helpers/cookie/setCityCookie";
import { checkCitySlug } from "src/models/cities/checkCitySlug";

interface CityState {
  name: string;
  slug: string;
}

const initialState: CityState = { name: "", slug: "" };

export const validationCity = createAsyncThunk(
  "/cities/validation",
  async (citySlug?: string) => {
    const currentCitySlug = citySlug || getCityCookie()?.slug;
    if (currentCitySlug) {
      const cityInfo = await checkCitySlug(currentCitySlug);
      return cityInfo.data;
    }
    return initialState;
  }
);

export const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    setCity: (state, action: PayloadAction<CityState>) => {
      setCityCookie(action.payload.slug, action.payload.name);
      return { slug: action.payload.slug, name: action.payload.name };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validationCity.fulfilled, (state, action) => {
      setCityCookie(action.payload.slug, action.payload.name);
      return { name: action.payload.name, slug: action.payload.slug };
    });
    builder.addCase(validationCity.rejected, (state) => {
      const getCity = getCityCookie();
      if (getCity.name && getCity.slug) {
        return { name: getCity.name, slug: getCity.slug };
      }
      return state;
    });
  },
});

export const citySliceActions = citySlice.actions;

export const selectCity = (state: AppState) => state.city;

export default citySlice.reducer;

import { useState } from "react";

export enum ModalType {
  AUTHORIZATION = "authorization",
  SEARCH = "search",
  CITY = "city",
  FEEDBACK = "feedback",
  CHECK_ORDER_STATUS = "checkOrderStatus",
  BRAND_PAGE_FILTERS_MODAL = "brandPageFilterModal",
}

const useModal = () => {
  const [modal, setModal] = useState<boolean | ModalType>(false);

  const openModal = (ModalType: ModalType) => {
    setModal(ModalType);
  };

  const closeModal = () => setModal(false);

  return { modal, openModal, closeModal };
};

export default useModal;

import { BasketState, PharmacyProduct } from "src/redux/slice/basketSlice";

export const reduceProduct = (obj: PharmacyProduct) =>
  obj
    ? Object.keys(obj).reduce((acc, item, index) => {
        return acc + obj[item].counter;
      }, 0)
    : 0;

export const calcProductInBasket = (basketData: BasketState) => {
  if (typeof window !== "undefined") {
    return Object.keys(basketData).reduce((acc, item, index) => {
      return acc + reduceProduct(basketData[item]);
    }, 0);
  }
  return 0;
};
